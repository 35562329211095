//Reset default styles

body, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, dl, dd, ol, ul, fieldset, form, menu,
select, textarea, input, button, optgroup{
	margin: 0;
	padding: 0;
}
ul{
	list-style: none;
}
a{
	background-color: transparent;
}
img, input, button{
	border: 0;
}
input{
	border-radius: 0;
}
input, textarea, select, button,
a:active, a:hover{
	outline: 0;
}
input[type="text"],
input[type="email"],
input[type="tel"],
textarea{
	-webkit-appearance: none;
}
input[type="submit"],
button{
	cursor: pointer;
	&::-moz-focus-inner{
		border: 0;
		padding: 0;
	}
}
textarea{
	overflow: auto;
}
table{
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}
td, th{
	padding: 0;
}
svg:not(:root){
	overflow: hidden;
}
main{
	display: block;
}

