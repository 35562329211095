/*
 * CSS Styles that are needed by jScrollPane for it to operate correctly.
 *
 * Include this stylesheet in your site or copy and paste the styles below into your stylesheet - jScrollPane
 * may not operate correctly without them.
 */

.jspContainer
{
  overflow: hidden;
  position: relative;
}

.jspPane
{
  position: absolute;
}

.jspVerticalBar
{
  background: red;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
}

.jspHorizontalBar
{
  background: red;
  bottom: 0;
  height: 16px;
  left: 0;
  position: absolute;
  width: 100%;
}

.jspCap
{
  display: none;
}

.jspHorizontalBar .jspCap
{
  float: left;
}

.jspTrack
{
  background: #dde;
  position: relative;
}

.jspDrag
{
  background: #bbd;
  cursor: pointer;
  left: 0;
  position: relative;
  top: 0;
}

.jspHorizontalBar .jspTrack,
.jspHorizontalBar .jspDrag
{
  float: left;
  height: 100%;
}

.jspArrow
{
  background: #50506d;
  cursor: pointer;
  direction: ltr;
  display: block;
  margin: 0;
  padding: 0;
  text-indent: -20000px;
}

.jspDisabled
{
  background: #80808d;
  cursor: default;
}

.jspVerticalBar .jspArrow
{
  height: 16px;
}

.jspHorizontalBar .jspArrow
{
  float: left;
  height: 100%;
  width: 16px;
}

.jspVerticalBar .jspArrow:focus
{
  border: 1px solid red;
  outline: none;
}

.jspCorner
{
  background: #eeeef4;
  float: left;
  height: 100%;
}

/* Yuk! CSS Hack for IE6 3 pixel bug :( */
* html .jspCorner
{
  margin: 0 -3px 0 0;
}

html {
  height: 100%;
}
body{
  margin: 0;
  background: #fff;
  color: @color-1;
  min-height: 100%;
  font-size: 16px;
  font-weight: 400;
  height: auto;
  font-family: 'Montserrat', Arial, sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  box-sizing: border-box;
  overflow-x: hidden;
  &.open-mobile-menu {
    overflow: hidden;
  }
}

input {
  font-family: 'Montserrat', Arial, sans-serif;
}

.container {
  width: 100%;
  max-width: 1260px;
  padding: 0 10px;
  margin: 0 auto;
  box-sizing: border-box;
  @media all and (max-width: 1300px) {
    padding: 0 60px;
  }
  @media all and (max-width: 767px) {
    padding: 0 4vw;
  }
}

//UI elements
.theme-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 331px;
  height: 54px;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: #ffffff;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: @color-3;
  text-decoration: none;
  transform: scale(1);
  transition: all 200ms ease-in-out;
  will-change: auto;
  &:hover {
    transform: scale(1.01);
  }
  &:active {
    background-color: #219E63;
    transform: scale(1.01);
  }
  @media all and (max-width: 767px) {
    width: 300px;
    height: 45px;
    font-size: 16px;
  }
}
.theme-button-small {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 34px;
  font-size: 16px;
  line-height: 20px;
  color: @color-3;
  box-sizing: border-box;
  border: 2px solid @color-3;
  border-radius: 30px;
  text-decoration: none;
  transform: scale(1);
  transition: all 200ms ease-in-out;
  will-change: auto;
  &:hover {
    border-color: @color-3;
    background-color: @color-3;
    color: #fff;
    transform: scale(1.05);
  }
  &:active {
    border-color: #219E63;
    background-color: #219E63;
    transform: scale(1.05);
  }
}
.main {
  padding: 140px 0;
  @media all and (max-width: 767px ) {
    padding: 75px 0;
  }
}
.main-title {
  font-size: 64px;
  font-weight: normal;
  font-family: "Arimo", Arial, sans-serif;
  line-height: 72px;
  text-align: center;
  margin-bottom: 50px;
  @media all and (max-width: 1500px) {
    font-size: 64px;
    line-height: 72px;
  }
  @media all and (max-width: 1300px) {
    font-size: 50px;
    line-height: 58px;
    margin-bottom: 45px;
  }
  @media all and (max-width: 1000px) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 30px;
  }
  @media all and (max-width: 767px) {
    font-size: 33px;
    line-height: 39px;
  }
  @media all and (max-width: 500px) {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 20px;
  }
}

//Header
@header-height: 100px;
.header {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: @header-height;
  background-color: #fff;
  transition: all 0.2s linear;
  will-change: auto;
  &.scroll {
    height: 70px;
    .header__nav {
      margin: 0 2vw;
    }
    .lang-dropdown {
      margin-left: 2vw;
    }
  }
  @media all and (max-width: 767px) {
    height: 70px;
    &__logo {
      img {
        width: 155px;
        display: block;
      }
    }
  }
}
.header__block {
  height: 100%;
  width: 100%;
  padding: 0 30px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 1000px) {
    padding-right: 70px;
  }
}
.header__nav {
  flex: 1 1 auto;
  margin: 0 4vw;
  will-change: auto;
  transition: all 0.2s linear;
}
.header__control {
  display: flex;
  align-items: center;
}
.header {
  .navigation {
  display: flex;
  > li {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  a {
    display: block;
    font-size: 16px;
    line-height: 20px;
    color: @color-1;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
      color: @color-3
    }
  }
}
.sub-menu {
  position: absolute;
  right: -32px;
  top: 33px;
  display: none;
  min-width: 140px;
  padding: 15px 25px;
  border: 1px solid @color-3;
  border-radius: 16px;
  box-sizing: border-box;
  background: #ffffff;
  &.open {
    display: block;
  }
  &::before {
    content: '';
    position: absolute;
    top: -20px;
    right: 27px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 20px 8px;
    border-color: transparent transparent @color-3 transparent
  }
  &::after {
    content: '';
    position: absolute;
    top: -18px;
    right: 27px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 20px 8px;
    border-color: transparent transparent #fff transparent
  }
  > li {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 17px;
    &:last-child {
      margin-bottom: 0;
    }
    > a {
      white-space: nowrap;
      font-size: 14px;
      line-height: 17px;
      color: @color-1
    }
  }
}
  .menu-item-has-children {
  position: relative;
}
.menu-item-has-children > a {
  padding-right: 12px;
  position: relative;
  color: @color-1;
  text-decoration: none;
  &.open {
    color: @color-3;
    &::after {
      border-color: @color-3 transparent transparent transparent;
    }
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 7px 4px 0 4px;
    border-color: rgba(16, 16, 15, 0.5) transparent transparent transparent;
  }
  &:hover {
    color: @color-3;
  }
}
}
.header-link {
  margin-left: 20px;
  &:first-of-type {
    margin-left: 0;
  }
  &.sign-in {
    border-color: transparent;
    color: @color-1;
    &:hover {
      color: #fff;
    }
  }
}
.lang-dropdown {
  margin-left: 4vw;
  transition: all 0.2s linear;
  will-change: auto;
  li {
    cursor: pointer;
    position: relative;
    &:hover {
      color: @color-3
    }
    &.active::before {
      content: url("../images/menu-arrow.svg");
      position: absolute;
      left: -15px;
      top: 0;
      line-height: 14px;
    }
  }
  @media all and (max-width: 1000px) {
    margin-left: 40px;
  }
}

//Intro
.intro-wrap {
  padding: 120px+@header-height 0 220px;
  position: relative;
  overflow: hidden;
  &__komondor {
    position: absolute;
    bottom: 0px;
    left: 15vw;
    opacity: 0;
  }
  &__line {
    position: absolute;
    left: 15vw;
    top: 0;
    width: 2px;
    height: 80%;
    transform: translateY(-100vw);
    background-color: @color-2;
  }
  @media all and (max-width: 1500px) {
    padding: 150px 0 120px;
    &__line {
      left: 1vw;
    }
    &__komondor {
      width: 600px;
    }
  }
  @media all and (max-width: 1300px) {
    padding: 120px 0 100px;
    &__line {
      left: 2vw;
    }
    &__komondor {
      width: 50vw;
    }
  }
  @media all and (max-width: 767px) {
    padding: 100px 0 25px;
    &__line {
      left: 5px;
    }
  }
}
.intro {
  display: flex;
  position: relative;
  &__image {
    position: absolute;
    top: 50%;
    right: -125px;
    transform: translateY(-50%);
    @media all and (max-width: 1000px) {
      top: 40%;
    }
  }
  @media all and (max-width: 1500px) {
    &__image {
      right: -50px;
    }
  }
  @media all and (max-width: 1300px) {
    &__image {
      right: -75px;
      width: 50%;
    }
  }
  @media all and (max-width: 767px) {

  }
  @media all and (max-width: 580px) {
    &__image {
      display: none;
    }
  }
}
.intro__title {
  font-family: "Arimo", sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 116%;
  width: 100%;
  max-width: 600px;
  margin-bottom: 5px;
  @media all and (max-width: 1500px) {
    font-size: 72px;
    line-height: 1;
    max-width: 675px;
    margin-bottom: 16px;
  }
  @media all and (max-width: 1300px) {
    font-size: 6vw;
    line-height: 1;
    max-width: 52vw;
  }
  @media all and (max-width: 767px) {
    font-size: 42px;
    line-height: 1;
    max-width: 370px;
  }
  @media all and (max-width: 580px) {
    font-size: 34px;
  }
}
.intro__sub {
  font-size: 22px;
  font-weight: 500;
  line-height: 120%;
  width: 100%;
  max-width: 415px;
  margin-bottom: 30px;
  @media all and (max-width: 1300px) {
    font-size: 18px;
    max-width: 350px;
  }
  @media all and (max-width: 767px) {
    font-size: 16px;
  }
}
.intro__action {
  display: flex;
  align-items: flex-end;
  @media all and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
.intro__button {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.intro__label {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}
.intro__text {
  height: 54px;
  display: flex;
  align-items: center;
  margin-left: 15px;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  max-width: 192px;
}
.intro-laptop {
  position: relative;
  &.hover:hover &__block {
    transform: translateY(0) rotate(22deg) !important;
  }
  &.hover:hover &__border {
    transform: rotate(0) !important;
  }
  &.hover &__block, &.hover &__border {
    transition: all 0.2s ease-in-out;
  }
  &__block {
    position: absolute;
    top: -18%;
    right: 12%;
    width: 60%;
    height: 135%;
    background-color: @color-2;
    transform: translateY(100vw);
    opacity: 0;
  }
  &__border {
    position: absolute;
    top: -18%;
    right: 12%;
    width: 60%;
    height: 135%;
    border: 2px solid @color-3;
    box-sizing: border-box;
    opacity: 0;
  }
  &__img {
    position: relative;
    z-index: 3;
    max-width: 680px;
    border: 1px solid #333;
    border-top: 0;
    border-radius: 15px;
  }
  @media all and (max-width: 1500px) {
    &__img {
      width: 550px;
    }
  }
  @media all and (max-width: 1300px) {
    &__block {
      right: 20%;
    }
    &__border {
      right: 20%;
    }
  }
  @media all and (max-width: 1000px) {
    &__block {
      display: none;
    }
    &__border {
      display: none;
    }
  }
}

//Helps
.helps-wrap {
  margin: 160px 0;
  @media all and (max-width: 1500px) {
    margin: 80px 0 160px;
  }
  @media all and (max-width: 1300px) {
    margin: 60px 0 120px;
    .container {
      padding: 0 20px;
    }
  }
  @media all and (max-width: 767px) {
    .container {
      padding: 0 4vw;
    }
    margin: 60px 0;
  }
  @media all and (max-width: 500px) {
    margin: 30px 0;
  }
}
.helps__block {
  width: 100%;
  display: flex;
  min-width: 0;
  min-height: 0;
  @media all and (max-width: 1000px) {
    display: block;
  }
}
.helps__instruments {
  width: 365px;
  @media all and (max-width: 1000px) {
    width: 100%;
  }
}
.helps__tabs {
  width: ~'calc(100% - 365px)';
  padding-left: 70px;
  box-sizing: border-box;
  @media all and (max-width: 1300px) {
    padding-left: 35px;
  }
  @media all and (max-width: 1000px) {
    padding-left: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
  }
}
.instruments {
  width: 100%;
  height: 534px;
  overflow: auto;
  outline: none;
  @media all and (max-width: 1300px) {
    height: 435px;
  }
  @media all and (max-width: 1000px) {
    height: initial;
    overflow: initial;
    margin-bottom: 50px;
  }
}
.instruments__items {
  padding: 20px 0 20px 40px;
  width: 312px;
  box-sizing: border-box;
  @media all and (max-width: 1300px) {
    padding: 12px 0 12px 40px;
  }
  @media all and (max-width: 600px) {
    padding: 12px 0 12px 0px;
  }
}
.instruments__title {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 25px;
  color: @color-1;
  position: relative;
  &::before {
    content: url("../images/instruments-title.svg");
    position: absolute;
    left: -30px;
    top: 0;
    opacity: 0;
    transform: translateX(-20px);
    transition: all 0.2s linear;
    @media all and (max-width: 600px) {
      left: -20px;
    }
  }
  &.hover {
    color: @color-3;
    ~ a {
      color: @color-3;
    }
    &::before {
      opacity: 1;
      transform: translateX(0);
    }
  }
  a {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    text-decoration: none;
  }
  @media all and (max-width: 1300px) {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 15px;
  }
}
.instruments__text {
  font-size: 16px;
  line-height: 20px;
  color: @color-1;
  margin-bottom: 10px;
  @media all and (max-width: 1300px) {
    font-size: 16px;
    line-height: 20px;
  }
}
.instruments__link {
  display: inline-block;
  font-size: 18px;
  line-height: 22px;
  color: @color-1;
  text-decoration: none;
  &:hover {
    color: @color-3;
  }
}
.tabs {
  min-width: 0;
  min-height: 0;
  //display: flex;
  width: 100%;
  flex-direction: column;
  &__links {
    margin-bottom: 40px;
      .tabs__item {
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 200px !important;
      height: 38px;
      box-sizing: border-box;
      padding-bottom: 4px;
      position: relative;
      margin: 0 22px;
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
      color: @color-1;
      text-decoration: none;
        cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 4px;
        background-color: @color-5;
        border-radius: 2px;
      }
      &.active {

      }
      @media all and (max-width: 1300px) {
        width: 165px !important;
        height: 34px;
        margin: 0 10px;
        font-size: 18px;
        line-height: 24px;
        &::before {
          height: 2px;
        }
      }
        @media all and (max-width: 767px) {
          width: 130px !important;
          height: 30px;
          margin: 0 6px;
          font-size: 16px;
          line-height: 20px;
        }
        @media all and (max-width: 500px) {
          height: 40px;
          text-align: center;
          width: 100% !important;
        }
    }
    @media all and (max-width: 1300px) {
      margin-bottom: 20px;
    }
    @media all and (max-width: 767px) {
      justify-content: space-between;
    }
  }
  .slick-current {
    .tabs__item {
      pointer-events: none;
      &::before {
        background-color: @color-2;
      }
    }
  }
  &__contents {
    //flex: 1 1 auto;
    position: relative;
    img {
      position: static;
      display: none;
      left: 50%;
      top: 0;
      width: auto !important;
      //height: 100%;
      height: auto;
      max-width: 100%;
      //transform: translateX(-50%);
      border: 1px solid @color-3;
      box-sizing: border-box;
    }

  }
}
.jspVerticalBar {
  width: 4px !important;
  border-radius: 2px;
  overflow: hidden;
  background: transparent;
}
.jspTrack {
  background-color: @color-5;
  border-radius: 2px;
}
.jspDrag {
  background-color: @color-2;
  border-radius: 2px;
}

//Join section
.join-wrap {
  margin: 160px 0;
  @media all and (max-width: 1500px) {
    margin: 120px 0;
  }
  @media all and (max-width: 767px) {
    margin: 60px 0;
  }
  @media all and (max-width: 500px) {
    margin: 30px 0;
  }
}
.join {
  display: flex;
  &__form {
    flex: 0 0 auto;
    @media all and (max-width: 1500px) {
      margin-left: 40px;
    }
    @media all and (max-width: 1300px) {
      margin-left: 0;
    }
  }
  &__info {
    flex: 1 1 auto;
  }
  @media all and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    &__form {
      order: 2;
      flex: none;
    }
    &__info {
      flex: none;
    }
  }
}
.form-theme {
  position: relative;
  width: 552px;
  height: 501px;
  padding: 50px;
  box-sizing: border-box;
  &__border {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    left: 0;
    top: 0;
    box-sizing: border-box;
    border: 2px solid @color-3;
    transform: rotate(-15deg);
  }
  &__block {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    background-color: @color-2;
    transform: translateY(200%);
    opacity: 0;
  }
  &__title {
    font-family: "Arimo", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    color: @color-1;
    text-align: center;
    margin-bottom: 36px;
    @media all and (max-width: 500px) {
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 26px;
    }
  }
  &__input {
    position: relative;
    margin-bottom: 20px;
    text-align: center;
    option {
      color: @color-1
    }
    input:not([type='checkbox']), select {
      display: block;
      width: 100%;
      height: 50px;
      padding: 0 40px;
      border: 1px solid @color-2;
      box-sizing: border-box;
      border-radius: 30px;
      font-size: 18px;
      font-family: "Montserrat", Arial, sans-serif;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      &::-webkit-input-placeholder {
        color: #cccccc;
      }
      &::-moz-placeholder {
        color: #cccccc;
      }
      &:-ms-input-placeholder {
        color: #cccccc;
      }
      &:-moz-placeholder {
        color: #cccccc;
      }
      &:focus {
        border-color: @color-3;
        &::-webkit-input-placeholder {
          color: @color-1;
        }
        &::-moz-placeholder {
          color: @color-1;
        }
        &:-ms-input-placeholder {
          color: @color-1;
        }
        &:-moz-placeholder {
          color: @color-1;
        }
      }
      @media all and (max-width: 500px) {
        height: 44px;
        padding: 0 20px;
        font-size: 16px;
      }
    }

  }
  &__checkbox {
    text-align: center;
    display: inline-block;
    margin: 0 auto;
    color: @color-1;
    -webkit-appearance: none;
    appearance: none;
    input {
      display: none;
    }
    input:checked + .checkbox-text::after {
      display: block;
    }
    .checkbox-text {
      position: relative;
      display: inline-block;
      padding-left: 38px;
      font-size: 16px;
      line-height: 20px;
      color: @color-1;
      cursor: pointer;
      @media all and (max-width: 1300px) {
        font-size: 14px;
        line-height: 18px;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -5px;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: 1px solid #29CB7E;
        box-sizing: border-box;
        @media all and (max-width: 1300px) {
          width: 25px;
          height: 25px;
          top: -3px;
        }
      }
      &::after {
        content: '';
        display: none;
        position: absolute;
        left: 14px;
        top: -12px;
        width: 12px;
        height: 28px;
        box-sizing: border-box;
        border-right: 1px solid @color-3;
        border-bottom: 1px solid @color-3;
        transform: rotate(45deg);
        @media all and (max-width: 1300px) {
          left: 12px;
          top: -9px;
          width: 10px;
          height: 24px;
        }
      }
    }
    a {
      color: @color-1;
      text-decoration: none;
    }
  }
  &__button {
    margin-top: 40px;
    input {
      margin: 0 auto;
      background: @color-3 !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    @media all and (max-width: 500px) {
      margin-top: 30px
    }
  }
  &__row {
    text-align: center;
    > * {
      margin: 0 10px;
    }
    @media all and (max-width: 500px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      > * {
        margin: 0 0 15px;
      }
    }
  }
  a {
    font-size: 16px;
    line-height: 20px;
    color: @color-1;
    text-decoration: none;
    border-bottom: 1px solid @color-3;
    @media all and (max-width: 1300px) {
      font-size: 14px;
      line-height: 18px;
    }
    &:hover {
      border-color: transparent;
    }
  }
  &__caption {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    @media all and (max-width: 1300px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  @media all and (max-width: 1500px) {
    width: 542px;
    height: 491px;
    padding: 50px;
  }
  @media all and (max-width: 1300px) {
    width: 440px;
    height: 440px;
    padding: 30px;
    &__title {
      font-size: 26px;
      line-height: 30px;
      margin-bottom: 26px;
    }
  }
  @media all and (max-width: 767px) {
    &__border {
      transform: rotate(-8deg);
    }
  }
  @media all and (max-width: 500px) {
    width: 100%;
    height: auto;
    padding: 30px 10px;
  }
}

.join__info {
  margin-left: 100px;
  margin-top: 100px;
  @media all and (max-width: 1500px) {
    margin-left: 70px;
  }
  @media all and (max-width: 1000px) {
    margin-top: 0;
    margin-bottom: 70px;
  }
  @media all and (max-width: 767px) {
    margin-left: 0;
  }
}
.join__title {
  font-family: "Arimo", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 40px;
  @media all and (max-width: 1300px) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
  }
}
.join__sub-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  @media all and (max-width: 1300px) {
    font-size: 18px;
    line-height: 22px;
  }
}

//Say section
.say-wrap {
  margin: 160px 0;
  @media all and (max-width: 1500px) {
    margin: 120px 0;
  }
  @media all and (max-width: 767px) {
    margin: 60px 0;
  }
  @media all and (max-width: 500px) {
    margin: 30px 0;
  }
}
.say {
  @media all and (max-width: 1500px) {
    max-width: 1100px;
    margin: 0 auto;
  }
}
.say-slider__item {
  width: 624px;
  height: 320px;
  transition: 0.3s all linear;
  margin-bottom: 40px;
  box-sizing: border-box;
  @media all and (max-width: 500px) {
    height: auto;
  }
}
.say-slider__content {
  width: 608px;
  height: 240px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid @color-1;
  border-radius: 180px;
  padding: 50px 50px 50px 80px;
  font-size: 16px;
  line-height: 20px;
  margin: 0 20px 20px;
  transition: 0.3s all linear;
  &::before {
    content: '“';
    position: absolute;
    left: 0;
    top: 0;
    font-family: "Arimo", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 200px;
    line-height: 230px;
    color: rgba(16, 16, 15, 0.1);
    transition: 0.3s all linear;
  }
  &::after {
    content: '';
    position: absolute;
    left: 22px;
    bottom: -13px;
    width: 26px;
    height: 26px;
    border-radius: 100%;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    transition: 0.3s all linear;
  }
  @media all and (max-width: 1500px) {
    margin: 0 auto 20px;
  }
  @media all and (max-width: 1300px) {
    width: 550px;
    padding: 50px 50px 30px 80px;
  }
  @media all and (max-width: 767px) {
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    &::after {
      left: 5px;
      bottom: -20px;
      width: 15px;
      height: 15px;
    }
  }
  @media all and (max-width: 500px) {
    height: auto;
    margin-bottom: 25px;
  }
}
.say-slider__bio {
  display: flex;
  align-items: center;
}
.say-slider__avatar {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 20px;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
  }
  @media all and (max-width: 500px) {
    font-size: 18px;
    line-height: 22px;
    margin-right: 10px;
  }
}
.say-slider__name {
  font-size: 24px;
  line-height: 29px;
  font-weight: 500;
  @media all and (max-width: 1300px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media all and (max-width: 500px) {
    font-size: 18px;
    line-height: 22px;
  }
}
.say-slider__post {
  font-size: 18px;
  line-height: 22px;
  @media all and (max-width: 1300px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media all and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
}
.slick-slide {
  outline: none;
}
.slick-current {
  .say-slider__item {
    transform: translateY(40px);

  }
  .say-slider__content {
    border-color: @color-3;
    &::before {
      left: -15px;
      top: -20px;
      color: @color-2;
      @media all and (max-width: 767px) {
        left: -23px;
        top: -94px;
        font-size: 150px;
      }
    }
    &::after {
      border-color: @color-3;
    }
  }
}
.say {
  position: relative;
}
.say-slider-arrow {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  background: url("../images/slider-arrow.svg") no-repeat center center;
  cursor: pointer;
}
.say-slider-previous {
  left: -150px;
  @media all and (max-width: 1500px) {
    left: -60px;
  }
  @media all and (max-width: 1000px) {
    left: -30px;
  }
  @media all and (max-width: 767px) {
    left: -20px;
  }
}
.say-slider-next {
  right: -150px;
  transform: rotate(180deg);
  @media all and (max-width: 1500px) {
    right: -60px;
  }
  @media all and (max-width: 1000px) {
    right: -30px;
  }
  @media all and (max-width: 767px) {
    right: -20px;
  }
}

//Partners Wrap
.partners-wrap {
  margin: 160px 0;
  position: relative;
  @media all and (max-width: 1500px) {
    margin: 120px 0;
    &__border-right {
      top: ~'calc(50% + 35px)';
    }
    &__border-bottom {
      bottom: -35px;
    }
  }
  @media all and (max-width: 767px) {
    margin: 60px 0;
    &__line {
      top: 0;
      max-height: 75%;
    }
    &__border-right {
      top: ~'calc(50% + 15px)';
    }
    &__border-bottom {
      bottom: -15px;
    }
  }
  @media all and (max-width: 500px) {
    margin: 30px 0;
    &__line {
      left: 5vw;
    }
    &__border-right {
      right: 5vw;
    }
    &__border-bottom {
      right: 5vw;
    }
  }
  .container {
    max-width: 1260px;
    @media all and (max-width: 1500px) {
      max-width: 1100px;
    }
  }
  &__line {
    position: absolute;
    left: 15vw;
    top: -15%;
    width: 2px;
    height: 0;
    background-color: @color-2;
  }
  &__border-right {
    position: absolute;
    right: 15vw;
    top: ~'calc(50% + 70px)';
    height: 0;
    width: 1px;
    background-color: @color-3;
  }
  &__border-bottom {
    position: absolute;
    right: 15vw;
    bottom: -70px;
    height: 1px;
    width: 0;
    max-width: 900px;
    background-color: @color-3;
  }
}
.partners-block {

}
.partners-block__item {
  position: relative;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  justify-items: center;
  align-items: center;
  img {
    display: block;
    width: auto;
    max-width: 250px;
    margin: 0 auto;
    @media all and (max-width: 1500px) {
      max-width: 125px;
    }
    @media all and (max-width: 1000px) {
      max-width: 90px;
      max-height: 90px;
    }
  }
  @media all and (max-width: 767px) {
    grid-template-columns: 25% 25% 25% 25%;
  }
  @media all and (max-width: 500px) {
    grid-template-columns: 50% 50%;
  }
}

//Save Wrap
.save-wrap {
  margin: 160px 0;
  @media all and (max-width: 1500px) {
    margin: 120px 0;
  }
  .container {
    @media all and (max-width: 1500px) {
      max-width: 1160px;
    }
  }
  @media all and (max-width: 767px) {
    margin: 60px 0;
  }
  @media all and (max-width: 500px) {
    margin: 30px 0;
  }
}
.save-block {
  display: flex;
  align-items: center;
  &__content {
    flex: 0 0 580px;
    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 139%;
    }
    p {
      font-size: 16px;
      line-height: 139%;
      margin-bottom: 30px;
    }
    ul {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      li {
        position: relative;
        padding-left: 30px;
        font-weight: 500;
        font-size: 20px;
        line-height: 139%;
        cursor: pointer;
        a {
          color: inherit;
          text-decoration: none;
        }
        &::before {
          content: '';
          width: 20px;
          height: 20px;
          background: url("../images/menu-arrow.svg") no-repeat center;
          background-size: 80%;
          position: absolute;
          left: 0;
          top: 6px;
          line-height: 14px;
          opacity: 0.3;
        }
        &.active {
          color: @color-3;
          &::before {
            opacity: 1;
          }
        }
      }
    }
    @media all and (max-width: 1300px) {
      p {
        font-size: 16px;
        margin-bottom: 20px;
      }
      ul {
        li {
          font-size: 20px;
        }
      }
    }
  }
  &__image {
    flex: 1 1 auto;
    margin-left: 70px;
    @media all and (max-width: 1500px) {
      margin-left: 45px;
    }
    @media all and (max-width: 1300px) {
      margin-left: 20px;
      img {
        width: 100%;
      }
    }
  }
  @media all and (max-width: 1300px) {
    &__content {
      flex: 0 0 430px;
    }
  }
  @media all and (max-width: 767px) {
    flex-direction: column;
  }
}
.save-timeline {
  display: flex;
  justify-content: space-between;
  text-align: center;
  max-width: 1360px;
  margin: 60px auto 0;
  @media all and (max-width: 1500px) {
    max-width: 1260px;
  }
  &__item {
    width: 20%;
    position: relative;
    &:last-child {
      &::before, &::after {
        display: none;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 8px;
      right: ~'calc(-50% + 28px)';
      width: ~'calc(100% - 56px)';
      height: 4px;
      box-sizing: border-box;
      border-radius: 2px;
      border: 1px solid @color-3;
    }
    &::after {
      content: '';
      position: absolute;
      top: 8px;
      right: ~'calc(-50% + 28px)';
      width: ~'calc(100% - 56px)';
      height: 4px;
      box-sizing: border-box;
      border-radius: 2px;
      background-color: @color-3;
    }
  }
  &__dot {
    width: 28px;
    height: 16px;
    border: 1px solid @color-2;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    margin: 0 auto 24px;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: @color-2;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
  }
  &__text {
    font-size: 14px;
    line-height: 17px;
  }
  @media all and (max-width: 1300px) {
    margin: 50px auto 0;
    &__title {
      font-size: 18px;
      line-height: 22px;
    }
  }
  @media all and (max-width: 1000px) {
    &__title {
      font-size: 16px;
      line-height: 20px;
    }
  }
  @media all and (max-width: 767px) {
    &__title {
      font-size: 14px;
      line-height: 18px;
    }
  }
  @media all and (max-width: 500px) {
    flex-direction: column;
    text-align: left;
    padding: 0 4vw;
    &__item {
      width: 100%;
      margin-bottom: 20px;
      &::before {
        top: 48%;
        height: 100%;
        width: 4px;
        right: 12px;
      }
      &::after {
        top: 48%;
        height: 100%;
        width: 4px;
        right: 12px;
      }
    }
    &__dot {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

//Access Wrap
.access-wrap {
  padding-bottom: 160px;
  overflow: hidden;
}
.access__title {
  font-weight: 400;
  margin-bottom: 20px;
}
.access__block {
  display: flex;
  justify-content: center;
  margin-bottom: 130px;
  @media all and (max-width: 1300px) {
    margin-bottom: 80px;
  }
  @media all and (max-width: 500px) {
    margin-bottom: 40px;
    flex-direction: column;
  }
}
.access__item {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  padding-left: 38px;
  position: relative;
  margin: 0 40px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 28px;
    height: 16px;
    border-radius: 8px;
    background-color: @color-2;
  }
  @media all and (max-width: 1300px) {
    font-size: 20px;
    line-height: 25px;
  }
  @media all and (max-width: 1000px) {
    margin: 0 15px;
  }
  @media all and (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
    margin: 0px 8px;
    &::before {
      top: 2px;
    }
  }
}
.access__form {
  display: flex;
  justify-content: center;
  .form-theme {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__border {
      transform: rotate(15deg);
    }
  }
  @media all and (max-width: 500px) {
    margin: 0 -4vw;
  }
}

//Footer Wrap
.footer-wrap {
  padding: 50px 0 30px;
  background-color: @color-3;
  .container {
    max-width: 1260px;
    @media all and (max-width: 1500px) {
      max-width: 1260px;
    }
    @media all and (max-width: 1300px) {
      padding: 0 30px;
    }
    @media all and (max-width: 1000px) {
      padding: 0 15px;
    }
  }
  @media all and (max-width: 1300px) {
    padding: 30px 0;
  }
}
.footer {
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    @media all and (max-width: 1000px) {
      margin-bottom: 30px;
    }
    @media all and (max-width: 500px) {
      flex-direction: column;
    }
  }
  &__contacts {
    display: flex;
    flex-direction: column;
    text-align: right;
    a {
      font-size: 16px;
      line-height: 20px;
      color: @color-1;
      margin-bottom: 5px;
      text-decoration: none;
      &:last-child {
        margin-bottom: 0;
      }
    }
    @media all and (max-width: 500px) {
      text-align: center;
      margin-top: 20px;
    }
  }
  &__block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    @media all and (max-width: 1500px) {
      margin-bottom: 30px;
    }
    @media all and (max-width: 1300px) {
      flex-wrap: wrap;
    }
    @media all and (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__navigation {
    display: flex;
    @media all and (max-width: 1000px) {
      order: 1;
    }
    @media all and (max-width: 767px) {
      width: 100%;
    }
  }
  &__item {
    //display: grid;
    //grid-template-columns: 1fr 2fr;
    &:last-child {
      margin-right: 0;
    }
    > li {
      margin-bottom: 10px;
      > a {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
      &.menu-item-has-children {
        > a {
          margin-bottom: 10px;
          display: inline-block;
          font-weight: 500;
          font-size: 16px;
        }
        ul {
          margin-top: -5px;
          margin-bottom: 10px;
          li {
            margin-bottom: 5px;
            > a {
              font-size: 14px;
              line-height: 17px;
            }
          }
        }
      }
    }
  }
  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  &__list {

  }
  &__socials {
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 215px;
      height: 50px;
      padding: 5px 23px;
      border: 1px solid @color-1;
      box-sizing: border-box;
      border-radius: 30px;
      margin-bottom: 20px;
      text-align: right;
    }
    @media all and (max-width: 1300px) {
      margin-top: 40px;
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
    }
    @media all and (max-width: 1000px) {
      order: 3;
      margin-top: 30px;
      a {
        width: 170px;
        height: 45px;
        font-size: 14px;
        margin: 0 5px;
      }
    }
    @media all and (max-width: 767px) {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      a {
        margin: 5px;
      }
    }
  }
  &__subscribes {
    @media all and (max-width: 1000px) {
      order: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      margin-bottom: 30px;
    }
    @media all and (max-width: 767px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
  }
  a {
    color: @color-1;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    margin-left: 20px;
    a {
      display: inline-block;
    }
    @media all and (max-width: 1000px) {
      margin-bottom: 7px;
    }
    @media all and (max-width: 767px) {
      margin-left: 0;
      margin-bottom: 15px;
    }
  }
  &__sign-in {
    margin-right: 40px;
  }
  &__sign-up {
    padding: 5px 20px;
    border: 2px solid @color-1;
    box-sizing: border-box;
    border-radius: 30px;
  }
  &__label {
    margin-left: 20px;
    margin-bottom: 14px;
    @media all and (max-width: 1000px) {
      margin-bottom: 5px;
    }
    @media all and (max-width: 767px) {
      text-align: center;
      margin-left: 0;
    }
  }
  &__input {
    display: flex;
    input:not([type=submit]) {
      width: 300px;
      height: 50px;
      box-sizing: border-box;
      border: 2px solid #fff;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      background-color: transparent;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      padding: 0 40px;
      &::-webkit-input-placeholder {
        color: #ffffff;
      }
      &::-moz-placeholder {
        color: #ffffff;
      }
      &:-ms-input-placeholder {
        color: #ffffff;
      }
      &:-moz-placeholder {
        color: #ffffff;
      }
      @media all and (max-width: 1500px) {
        width: 280px;
      }
      @media all and (max-width: 1000px) {
        width: 260px;
        height: 45px;
      }
      @media all and (max-width: 500px) {
        width: 200px;
        padding: 0 15px;
      }
    }
    input[type=submit] {
      width: 110px;
      height: 50px;
      box-sizing: border-box;
      background-color: #ffffff;
      text-align: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      font-size: 16px;
      line-height: 20px;
      color: @color-3;
      @media all and (max-width: 1000px) {
        height: 45px;
      }
      @media all and (max-width: 500px) {
        width: 90px;
      }
    }
  }
  &__bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    @media all and (max-width: 1300px) {
      margin-bottom: 40px;
    }
    @media all and (max-width: 500px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__links {
    a {
      margin-right: 100px;
      @media all and (max-width: 767px) {
        margin-right: 15px;
      }
    }
    @media all and (max-width: 500px) {
      display: flex;
      flex-direction: column;
      text-align: center;
      a {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
  &__copy {
    text-align: center;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.header__overlay {
  display: none;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  &.open {
    display: block;
  }
}
.hamburger {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  flex-direction: column;
  justify-content: space-between;
  width: 45px;
  height: 45px;
  box-sizing: border-box;
  padding: 10px 5px;
  display: none;
  cursor: pointer;
  &__line {
    height: 3px;
    width: 100%;
    background-color: @color-1;
  }
}
.header-nav-close {
  display: none;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 30px;
  height: 30px;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: #cccccc;
    transform: translate(-50%,-50%) rotate(45deg);
  }
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: #cccccc;
    transform: translate(-50%,-50%) rotate(-45deg);
  }
}

//Instruments Page
.intro-instruments-wrap {
  padding: 150px 0 75px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 82px;
    top: 0;
    width: 1px;
    height: 60%;
    background-color: #FFC90B;
  }
}
.intro-instruments {

  &__title {
    font-family: Arimo;
    font-weight: bold;
    font-size: 60px;
    line-height: 1.1;
    text-align: center;
    color: #10100F;
    max-width: 1240px;
    margin: 0 auto 40px;
  }
  &__text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    max-width: 1024px;
    margin: 0 auto 50px;
  }
  &__links {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    a {
      margin: 0 15px 30px;
    }
  }
  &__download {
    background-color: transparent;
    border: 2px solid #29CB7E;
    color: #29CB7E;
    &::before {
      content: '';
      width: 28px;
      height: 28px;
      background: url('../images/google.svg') no-repeat center;
      background-size: contain;
      margin-right: 20px;
    }
  }
  &__image {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  &__list {
    padding: 20px 0;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 80%;
      height: 130%;
      border-right: 1px solid #29CB7E;
      border-bottom: 1px solid #29CB7E;
    }
    ul {
      margin-bottom: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 -50px;
      li {
        position: relative;
        padding-left: 30px;
        font-weight: 500;
        font-size: 20px;
        line-height: 139%;
        width: 260px;
        box-sizing: border-box;
        margin: 0 50px 50px;
        &::before {
          content: '';
          width: 20px;
          height: 20px;
          background: url("../images/menu-arrow.svg") no-repeat center;
          background-size: 80%;
          position: absolute;
          left: 0;
          top: 6px;
          line-height: 14px;
          opacity: 1;
        }
      }
    }
  }
}
.intro-instruments-image {
  position: relative;
  margin-bottom: 100px;
  img {
    display: block;
  }
  &__border {
    position: absolute;
    z-index: -2;
    //left: -250px;
    //top: -25px;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 296px;
    border: 2px solid #29CB7E;
    box-sizing: border-box;

    transform: rotate(0deg) translateX(0);
    transition: 300ms transform 200ms ease-in-out,
                200ms width 0ms ease-in-out,
                200ms left 0ms ease-in-out,
                200ms bottom 0ms ease-in-out;

  }
  &__block {
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 296px;
    background: #FFC90B;

    transition: all 200ms ease-in-out;
  }
  &:hover {
    .intro-instruments-image__border {
      transform: rotate(0) translateX(0) !important;
      transition: all 200ms ease-in-out !important;
    }
    .intro-instruments-image__block {
      transform: rotate(30deg) translateX(-140px) !important;
      transition: all 200ms ease-in-out !important;
    }
  }
  &.hover {
    .intro-instruments-image__border {
      transition: all 200ms ease-in-out !important;
    }
  }
  &.animate {
    .intro-instruments-image__border {
      width: 887px;
      left: -152px;
      bottom: -53px;
      transform: rotate(30deg) translateX(-140px);
    }
    .intro-instruments-image__block {
      width: 999px;
      left: -152px;
      bottom: -53px;
    }
  }
}

.content-instruments-wrap {
  padding: 100px 0 40px;
}
.content-instruments {
  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -30px 60px;
  }
  &__info {
    width: 100%;
    margin: 0 30px;
    max-width: 920px;
    h3 {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 20px;
      line-height: 1.5;
      color: #10100F;
      margin-bottom: 25px;
    }
    p {
      font-family: Montserrat;
      font-size: 16px;
      line-height: 1.5;
      color: #10100F;
      width: 100%;
      max-width: 870px;
    }
  }
  &__image {
    margin: 0 30px;
    padding: 25px;
    box-sizing: border-box;
    img {
      display: block;
      filter: drop-shadow(-4px -4px 8px rgba(0, 0, 0, 0.15));
    }
  }
}
.content-instruments-image {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF0BB;
    transition: all 200ms ease-in-out;
  }
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
    width: 90%;
    height: 80%;
    border-right: 1px solid #29CB7E;
    border-bottom: 1px solid #29CB7E;
    box-sizing: border-box;
    transition: all 200ms ease-in-out;
  }
  &.animate {
    &::before {
      left: -25px;
      top: -25px;
    }
    &::after {
      right: -25px;
      bottom: -25px;
    }
  }
}
.services-instruments-wrap {
  padding: 75px 0;
}
.services-instruments {
  &__title {
    font-family: Arimo;
    font-size: 60px;
    line-height: 1.1;
    text-align: center;
    color: #10100F;
    margin-bottom: 70px;
  }
  &__block {
    img {
      width: 100%;
    }
  }
}

.advantages-instruments-wrap {
  padding: 75px 0;
}
.advantages-instruments {
  &__title {
    font-family: Arimo;
    font-weight: 400;
    font-size: 60px;
    line-height: 1.1;
    text-align: center;
    color: #10100F;
    margin-bottom: 70px;
  }
  &__block {
    display: flex;
    margin: 0 -20px;
  }
  &__item {
    width: 33.3333%;
    padding: 0 20px;
  }
  &__icon {
    height: 110px;
    text-align: center;
    margin-bottom: 35px;
  }
  &__caption {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5;
    text-align: center;
    color: #10100F;
    margin-bottom: 25px;
  }
  &__text {
    font-family: Montserrat;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #10100F;
  }
}
.how-instruments-wrap {
  padding: 75px 0;
}
.how-instruments {
  &__title {
    font-family: Arimo;
    font-weight: 400;
    font-size: 66px;
    line-height: 1.1;
    text-align: center;
    color: #10100F;
    margin-bottom: 100px;
  }
  .save-timeline {
    max-width: 100%;
    &__item {
      width: 16.667%;
      padding: 0 10px;
      box-sizing: border-box;
    }
    &__text {
      font-family: Montserrat;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
    }
    &--20 {
      .save-timeline__item {
        width: 20%;
      }
    }
  }
}
.see-instruments-wrap {
  padding: 75px 0;
}
.see-instruments {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    right: -70px;
    bottom: 0;
    width: 70%;
    height: 75%;
    border-right: 2px solid #29CB7E;
    border-bottom: 2px solid #29CB7E;
  }
  &:hover {
    .see-instruments-images {
      transform: rotate(0);
    }
    .see-instruments-images--1 {
      left: 0;
      top: 0;
    }
    .see-instruments-images--2 {
      left: 53%;
      top: 0;
    }
    .see-instruments-images--3 {
      left: 75%;
      top: 0;
    }
    .see-instruments-images--4 {
      left: 34%;
      top: 66%;
    }
    .see-instruments-images--5 {
      left: 48%;
      top: 40%;
    }
    .see-instruments-images--6 {
      left: 83%;
      top: 39%;
    }
    .see-instruments-images--7 {
      left: 82%;
      top: 73%;
    }
  }
  &__images {
    width: 728px;
    height: 389px;
    position: relative;
  }
  &__info {
    margin-left: 50px;
  }
  &__title {
    font-family: Arimo;
    font-size: 28px;
    line-height: 1.1;
    color: #10100F;
    margin-bottom: 40px;
  }
  &__text {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.3;
    color: #10100F;
    margin-bottom: 50px;
    max-width: 640px;
  }
  .see-instruments-images {
    position: absolute;
    transition: all 300ms ease-in-out;
    &--1 {
      left: 10%;
      top: 13%;
      width: 50%;
      transform: rotate(-32deg);
    }
    &--2 {
      left: 56%;
      top: 0;
      width: 18%;
      transform: rotate(20.74deg);
    }
    &--3 {
      left: 64%;
      top: 21%;
      width: 24%;
      transform: rotate(50deg);
    }
    &--4 {
      left: 39%;
      top: 65%;
      width: 13%;
      transform: rotate(16.15deg);
    }
    &--5 {
      left: 49%;
      top: 40%;
      width: 32%;
      transform: rotate(24deg);
    }
    &--6 {
      left: 75%;
      top: 44%;
      width: 17%;
      transform: rotate(-12deg);
    }
    &--7 {
      left: 49%;
      top: 74%;
      width: 12%;
      transform: rotate(-29.43deg);
    }
  }
}

.try-instruments-wrap {
  padding: 75px 0 150px;
}
.try-instruments {
  &__title {
    font-family: Arimo;
    font-size: 66px;
    line-height: 1.1;
    text-align: center;
    color: #10100F;
    margin-bottom: 70px;
  }
  &__block {
    min-width: 0;
    min-height: 0;
    display: flex;
    margin: 0 -10px;
  }
  &__item {
    width: 33.3333%;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
  }
  &__ico {
    min-width: 80px;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid #FFC90B;
    border-radius: 100%;
    margin-right: 20px;
  }
  &__caption {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5;
    color: #10100F;
    margin: 25px 0 20px;
  }
  &__text {
    font-family: Montserrat;
    font-size: 14px;
    line-height: 1.5;
    color: #10100F;
    margin-bottom: 10px;
  }
  &__link {
    margin-bottom: 40px;
    a {
      display: inline-block;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.5;
      color: #10100F;
      border-bottom: 1px solid #29CB7E;
      text-decoration: none;
      &:hover {
        border-color: transparent;
      }
    }
  }
  &__more {
    text-align: center;
    margin-top: 60px;
    a {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #10100F;
      border-bottom: 1px solid #29CB7E;
      text-decoration: none;
      &:hover {
        border-color: transparent;
      }
    }
  }
}
.try-instruments-bullets {
  display: flex;
  margin: 0 -5px;
  &__item {
    width: 50%;
    padding: 0 4px;
    box-sizing: border-box;
  }
  &__ico {
    text-align: center;
    margin-bottom: 5px;
  }
  &__title {
    font-family: Montserrat;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: #10100F;
  }
}

.komondor-left {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 30px;
    top: 0;
    width: 130px;
    height: 100%;
    background: url('../images/komondor-left.png') no-repeat center;
    background-size: contain;
    @media all and (max-width: 1600px) {
      left: 10px;
      width: 100px;
    }
    @media all and (max-width: 1280px) {
      display: none;
    }
  }

}
.komondor-right {
  position: relative;
  overflow: unset;
  &::before {
    content: '';
    position: absolute;
    right: 30px;
    bottom: 30px;
    width: 187px;
    height: 100%;
    background: url('../images/komondor-right.png') no-repeat center;
    background-size: contain;
    @media all and (max-width: 1600px) {
      right: 10px;
      width: 140px;
    }
    @media all and (max-width: 1280px) {
      display: none;
    }
  }
}

.cat-inst-content-wrap {
  padding: 75px 0;
  &.last {
    padding: 200px 0;
  }
}

.cat-inst-content {
  position: relative;
  &__title {
    font-family: Arimo;
    font-size: 28px;
    line-height: 32px;
    color: #10100F;
    margin-bottom: 40px;
    max-width: 572px;
  }
  &__text {
    font-family: Montserrat;
    font-size: 18px;
    line-height: 1.5;
    color: #10100F;
    max-width: 572px;
    margin-bottom: 100px;
  }
  &__image {
    position: absolute;
    right: 0;
    top: 0;
    &.center {
      top: 50%;
      transform: translateY(-50%);
    }
    img {
      max-width: 100%;
    }
  }
  &__block {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    .try-instruments__text {
      max-width: 267px;
    }
    &--special {
      .try-instruments__item {
        &:first-child {
          width: 100%;
        }
      }
    }
  }
}

//Tariff
.tariff-intro-wrap {
  padding: 150px 0 75px;
}
.tariff-intro {
  &__title {
    font-family: Arimo;
    font-weight: bold;
    font-size: 60px;
    line-height: 1.3;
    text-align: center;
    color: #10100F;
    margin-bottom: 40px;
  }
  &__text {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    color: #10100F;
    margin-bottom: 50px;
  }

}

.tariff-radio {
  display: flex;
  justify-content: center;
  input {
    display: none;
  }
  input:checked + &__button {
    background-color: #29CB7E;
    color: #fff;
  }
  &__label {
    cursor: pointer;
    margin: 0 25px;
    position: relative;
  }
  &__button {
    background-color: transparent;
    border: 1px solid #29CB7E;
    color: #29CB7E;
    transform: scale(1) !important;
  }
  &__percent {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: Montserrat;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
    color: #10100F;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #29CB7E;
    box-sizing: border-box;
    border-radius: 100%;
  }
}

.tariff {
  &__radio {
    margin-bottom: 70px;
  }
  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__item {
    position: relative;
    width: 370px;
    height: 690px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 30px 0;
    &--special {
      padding: 55px 0;
      width: 400px;
      height: 740px;
      border: 1px solid #29CB7E;
    }
  }
  &__item:first-child &__list {
    li:last-child {
      &::before {
        display: none;
      }
    }
  }
  &__item--special &__title {
    margin-bottom: 25px;
  }
  &__item--special &__price {
    margin-bottom: 30px;
  }
  &__badge {
    position: absolute;
    top: 30px;
    right: -50px;
    background: #29CB7E;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    width: 200px;
    text-align: center;
    color: #FFFFFF;
    transform: rotate(45deg);
  }
  &__title {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #29CB7E;
    margin-bottom: 40px;
  }
  &__price {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 30px;
    line-height: 44px;
    text-align: center;
    color: #000000;
    margin-bottom: 40px;
    &--small {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #000000;
    }
  }
  &__caption {
    font-family: Montserrat;
    font-size: 16px;
    line-height: 1.25;
    text-align: center;
    color: #000000;
    max-width: 292px;
    margin: 0 auto 15px;
  }
  &__list {
    padding: 0 30px;
    margin-bottom: 20px;
    li {
      padding-left: 20px;
      position: relative;
      font-family: Montserrat;
      font-size: 14px;
      line-height: 1.5;
      color: #000000;
      margin-bottom: 10px;
      &::before {
        content: '';
        width: 10px;
        height: 10px;
        background: url('../images/menu-arrow.svg') no-repeat center;
        background-size: 80%;
        position: absolute;
        left: 0;
        top: 6px;
        line-height: 14px;
        opacity: 1;
      }
    }
  }
  &__button {
    width: auto;
    min-height: 44px;
    height: 44px;
    padding: 0 40px;
    margin-bottom: 20px;
  }
  &__more {
    display: block;
    margin: 0 auto;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    color: #10100F;
    border-bottom: 1px solid #29CB7E;
    text-decoration: none;
    &:hover {
      border-color: transparent;
    }
  }
}
.compare-wrap {
  padding: 75px 0;
}
.compare {
  &__title {
    font-family: Arimo;
    font-weight: normal;
    font-size: 60px;
    line-height: 1.25;
    text-align: center;
    color: #10100F;
    margin-bottom: 70px;
  }
}
.compare-block {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background: #FFFFFF;
  border: 1px solid #F6F6F6;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  border-radius: 17px 17px 0px 0px;
  > div {
    border: 1px solid #F6F6F6;
    padding: 25px 30px;
  }
  &__head {
    text-align: center;
  }
  &__title {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #29CB7E;
    margin-bottom: 15px;
  }
  &__price {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 26px;
    line-height: 1.3;
    color: #000000;
    margin-bottom: 10px;
  }
  &__text {
    font-family: Montserrat;
    font-size: 14px;
    line-height: 1.4;
    color: #000000;
    margin-bottom: 15px;
  }
  &__link {
    display: inline-block;
    background: #29CB7E;
    border-radius: 30px;
    padding: 10px 40px;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    box-sizing: border-box;
    text-decoration: none;
  }
  &__cell {
    font-family: Montserrat;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.2px;
    color: #10100F;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &__bg {
    background-color: #F6F6F6;
  }
  &__left {
    font-size: 14px;
    justify-content: flex-start;
    text-align: left;
  }
  &__color {
    color: #F24242;
  }
}
.faq-wrap {
  padding: 0 0 75px;
}
.faq-list {
  &__item {
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    margin-bottom: 15px;
  }
  &__question {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #10100F;
    padding: 20px 40px;
    position: relative;
    cursor: pointer;
    &:hover {
      color: #29CB7E;
    }
    &::after {
      content: '';
      position: absolute;
      top: 28px;
      right: 40px;
      width: 10px;
      height: 10px;
      background: url('../images/faq-arrow.svg') no-repeat center;
      background-size: contain;
      transform: rotate(180deg);
    }
    &.active {
      color: #29CB7E;
      &::after {
        transform: rotate(0);
      }
    }
  }
  &__answer {
    display: none;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #10100F;
    padding: 20px 40px;
  }
}

.not-found-wrap {
  padding-top: 180px;
  &::before {
    content: '';
    position: absolute;
    left: 82px;
    top: 0;
    width: 1px;
    height: 60%;
    background-color: #FFC90B;
  }
}
.not-found {
  display: flex;
  &__item {
    width: 50%;
  }
  &__title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5;
    color: #10100F;
    margin-bottom: 25px;
    img {
      vertical-align: middle;
    }
  }
  &__links {
    margin: 50px 0;
    a {
      display: inline-block;
      padding: 16px 50px;
      border: 2px solid #29CB7E;
      box-sizing: border-box;
      border-radius: 30px;
      font-family: Montserrat;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #29CB7E;
      margin-right: 15px;
      text-decoration: none;
      &:hover {
        background: #29CB7E;
        color: #fff;
      }
    }
  }
  &__number {
    font-family: Arimo;
    font-size: 360px;
    line-height: 1;
    color: #29CB7E;
  }
}

.tariff-partners-wrap {
  padding: 75px 0;
}
.tariff-partners {
  &__block {
    margin: 0 auto;
    max-width: 1650px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    img {
      height: auto;
      margin: 20px;
    }
  }
}
.try-instruments {
  position: relative;
}
.try-instruments__gallery {
  position: relative;
  padding: 0 40px;
  .try-instruments__item {
    display: flex !important;
    max-width: 420px;
    margin: 0 auto;
  }
  .try-instruments-bullets__ico {
    img {
      margin: 0 auto;
    }
  }
}
.try-instruments__gallery--arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 15px;
  &--prev {
    left: -40px;
    padding-left: 0;
  }
  &--next {
    right: -40px;
    padding-right: 0;
  }
}

//About
.about-intro-wrap {
  padding: 150px 0 50px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 82px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #FFC90B;
  }
}
.about-intro {
  position: relative;
  padding-bottom: 70px;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 80%;
    height: 60%;
    border-bottom: 1px solid #29CB7E;
    border-right: 1px solid #29CB7E;;
  }
  &__title {
    font-family: Arimo;
    font-weight: bold;
    font-size: 60px;
    line-height: 1.3;
    text-align: center;
    color: #10100F;
    margin-bottom: 70px;
  }
  &__block {
    display: flex;
  }
  &__content {
    font-family: Montserrat;
    font-size: 20px;
    line-height:1.5;
    color: #10100F;
    width: 100%;
    max-width: 874px;
    margin-right: 85px;
    p {
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__logos {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    img {
      &:first-child {
        margin-bottom: 117px;
      }
    }
  }
}
.about-team-wrap {
  padding: 75px 0;
}
.about-team {
  &__title {
    margin-bottom: 40px;
  }
  &__caption {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    color: #000000;
    max-width: 650px;
    margin: 0 auto 70px;
  }
  &__row {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  &__item {
    max-width: 255px;
    margin: 0 20px 50px;
    text-align: center;
  }
  &__photo {
    width: 140px;
    height: 140px;
    border: 1px solid #29CB7E;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto;
  }
  &__name {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin: 25px 0 10px;
  }
  &__text {
    font-family: Montserrat;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
  }
}
.join-wrap.komondor-right {
  padding: 75px 0;
}
.about-numbers-wrap {
  padding: 75px 0 25px;
}
.about-numbers {
  &__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  &__item {
    display: flex;
    align-items: center;
    margin: 0 10px 50px;
  }
  &__count {
    font-family: Arimo;
    font-size: 48px;
    line-height: 55px;
    text-align: center;
    color: #29CB7E;
    margin-right: 10px;
  }
  &__bold {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.1;
    color: #10100F;
  }
  &__text {
    font-family: Montserrat;
    font-size: 16px;
    line-height: 1.4;
    color: #10100F;
  }
}
.partners-block__link {
  margin: 50px auto 100px;
  width: 100%;
  max-width: 400px;
}

.page-blog {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  &__item {
    width: 33.3333%;
    padding: 0 15px;
    margin-bottom: 40px;
    box-sizing: border-box;
    text-decoration: none;
  }
  &__thumbnail {
    width: 100%;
    height: 260px;
    overflow: hidden;
    position: relative;
    margin-bottom: 15px;
    img {
      position: absolute;
      left: 0;
      top: 50%;
      height: auto;
      width: 100%;
      transform: translateY(-50%);
    }
  }
  &__title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5;
    color: #000000;
    margin-bottom: 15px;
  }
  &__date {
    font-family: Montserrat;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #000000;
  }
  @media all and (max-width: 1024px) {
    &__item {
      width: 50%;
    }
  }
  @media all and (max-width: 767px) {
    justify-content: center;
    margin: 0;
    &__thumbnail {
      height: 190px;
    }
    &__item {
      padding: 0;
      width: 100%;
      max-width: 350px;
      margin-bottom: 25px;
    }
    &__title {
      font-size: 16px;
    }
    &__date {
      font-size: 14px;
    }
  }
}

.wp-pagenavi {
  margin: 4vw 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.content {
  .blocks-gallery-grid .blocks-gallery-image figure, .blocks-gallery-grid .blocks-gallery-item figure, .wp-block-gallery .blocks-gallery-image figure, .wp-block-gallery .blocks-gallery-item figure {
    align-items: normal;
  }
  strong {
    font-weight: 700;
  }
  em {
    font-style: italic;
  }
  p {
    font-size: 18px;
    line-height: 1.67;
    color: #3f3d3f;
    margin-bottom: 25px;
  }
  img {
    display: block;
    margin-bottom: 25px;
    max-width: 100%;
  }
  a {
    color: inherit;
  }
  ul, ol {
    list-style: none;
    margin-bottom: 25px;
    li {
      position: relative;
      padding-left: 25px;
      font-size: 18px;
      line-height: 1.67;
      color: #3f3d3f;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 13px;
        border-radius: 100%;
        width: 7px;
        height: 7px;
        background-color: #FFC90B;
      }
    }
  }
  .wp-block-gallery {
    li {
      padding-left: 0;
      &::before {
        display: none;
      }
    }
  }
  .wp-blockquote {
    > p {
      font-size: 130%;
    }
  }
  h2              { font-size: 1.97em; margin: .75em 0 }
  h3              { font-size: 1.72em; margin: .83em 0 }
  h4              { font-size: 1.5em; margin: .83em 0 }
  h5              { font-size: 1.31em; margin: 1.5em 0 }
  h6              { font-size: 1.16em; margin: 1.67em 0 }
  h1, h2, h3, h4,
  h5, h6          { font-weight: bolder }
}
//Media
@media all and (max-width: 1600px) {
  .about-intro-wrap {
    padding: 120px 0 50px;
    &::before {
      left: 30px;
    }
  }
  .partners-wrap__line {
    left: 10vw;
  }
  .partners-wrap__border-right {
    right: 10vw;
  }
  .partners-wrap__border-bottom {
    right: 10vw;
  }
  .about-intro {
    &__title {
      margin-bottom: 50px;
    }
    &__content {
      font-size: 18px;
      p {
        margin-bottom: 20px;
      }
    }
  }
  .try-instruments-wrap {
    padding: 75px 0;
  }
  .about-team {

  }
  .not-found-wrap {
    padding-top: 130px;
    &::before {
      left: 15px;
      height: 50%;
    }
  }
  .not-found {
    align-items: center;
    &__number {
      font-size: 300px;
    }
  }
  .try-instruments__gallery--arrow {
    &--prev {
      left: -20px;
    }
    &--next {
      right: -20px;
    }
  }
}
@media all and (max-width: 1300px) {
  .about-intro {
    padding-bottom: 0;
    &::after {
      display: none;
    }
    &__title {
      font-size: 50px;
    }
  }
  .about-team {
    &__title {
      margin-bottom: 30px;
    }
    &__caption {
      font-size: 20px;
      max-width: 630px;
      margin: 0 auto 50px;
    }
    &__row {
      flex-wrap: wrap;
    }
  }
  .not-found-wrap {
    padding-top: 150px;
    &::before {
      left: 30px;
    }
  }
  .not-found {
    &__title {
      font-size: 18px;
      margin-bottom: 20px;
    }
    &__links {
      margin: 20px 0;
    }
    &__number {
      font-size: 240px;
    }
  }
  .tariff-intro-wrap {
    padding: 120px 0 75px;
  }
  .tariff-intro {
    &__title {
      font-size: 50px;
    }
    &__text {
      font-size: 20px;
    }
  }
  .tariff {
    &__title {
      margin-bottom: 25px;
    }
    &__price {
      font-size: 24px;
      line-height: 1.3;
      margin-bottom: 25px;
    }
    &__item {
      &--special {
        z-index: 10;
      }
    }
    &__list {
      padding: 0 20px;
    }
  }
  .tariff__item--special .tariff__title {
    margin-bottom: 15px;
  }
  .tariff__item--special .tariff__price {
    margin-bottom: 15px;
  }
  .compare-block {
    > div {
      padding: 15px;
    }
    &__price {
      font-size: 22px;
      small {
        font-size: 16px;
      }
    }
    &__link {
      padding: 10px 20px;
      font-size: 14px;
      line-height: 1.3;
    }
    &__cell {
      font-size: 14px;
    }
  }
  .partners-wrap__line {
    left: 6vw;
  }
  .partners-wrap__border-right, .partners-wrap__border-bottom {
    right: 6vw;
  }
  .intro-instruments-wrap {
    padding: 120px 0 75px;
    &::before {
      left: 30px;
    }
  }
  .intro-instruments {
    &__title {
      font-size: 50px;
    }
    &__text {
      font-size: 20px;
    }
    &__list {
      ul {
        margin: 0 -30px;
        li {
          margin: 0 30px 50px;
        }
      }
    }
  }
  .intro-instruments-image {
    margin-bottom: 50px;
    &.animate {
      .intro-instruments-image__border {
        width: 900px;
        left: -60px;
        bottom: -30px;
        transform: rotate(30deg) translateX(-140px);
      }
      .intro-instruments-image__block {
        width: 900px;
        left: -60px;
        bottom: -30px;
      }
    }
  }
  .content-instruments {
    &__block {
      flex-direction: column;
      margin: 0 0 40px;
    }
    &__info {
      order: 1;
      margin-bottom: 20px;
    }
    &__image {
      order: 2;
    }
  }
  .services-instruments, .advantages-instruments, .how-instruments, .try-instruments {
    &__title {
      font-size: 46px;
    }
  }
  .see-instruments {
    &::before {
      right: -30px;
    }
    &__images {
      height: 285px;
    }
  }
  .try-instruments {
    &__block {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    &__item {
      width: 50%;
      margin-bottom: 40px;
    }
    &__text {
      max-width: 260px;
    }
    &__more {
      margin-top: 20px;
    }
  }
  .try-instruments-bullets {
    &__item {
      width: 45%;
    }
  }
  .cat-inst-content {
    &__title {
      max-width: 750px;
      text-align: center;
      margin: 0 auto 30px;
    }
    &__text {
      max-width: 750px;
      margin: 0 auto 30px;
      text-align: center;
    }
    &__image {
      position: static;
      margin-bottom: 50px;
      text-align: center;
      &.center {
        transform: translateY(0);
      }
    }
    &__block {
      &--special {
        .try-instruments__item {
          &:first-child {
            width: 50%;
          }
        }
      }
    }
  }
  .cat-inst-content-wrap {
    padding: 75px 0;
    &.last {
      padding-bottom: 100px;
    }
  }
}
@media all and (max-width: 1000px) {
  .about-intro-wrap {
    padding: 100px 0 50px;
  }
  .about-intro {
    &__title {
      font-size: 40px;
      margin-bottom: 40px;
    }
    &__block {
      flex-direction:column
    }
    &__content {
      font-size: 16px;
      margin-bottom: 30px;
    }
    &__logos {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      img {
        margin: 0 20px !important;
      }
    }
  }
  .about-team-wrap {
    padding: 50px 0 0;
  }

  .about-team {
    &__title {
      margin-bottom: 30px;
    }
    &__caption {
      font-size: 18px;
      max-width: 570px;
      margin: 0 auto 40px;
    }
    &__item {
      min-width: 230px;
      width: 230px;
    }
  }
  .about-numbers-wrap {
    padding: 50px 0;
  }
  .about-numbers {
    &__block {
      margin: 0 -20px;
    }
    &__item {
      width: 50%;
      margin: 0 0 50px;
      padding: 0 20px;
      box-sizing: border-box;
    }
    &__bold {
      font-size: 18px;
    }
  }
  .try-instruments__gallery {
    display: block;
  }
  .not-found-wrap {
    padding-top: 120px;
    &::before {
      left: 10px;
    }
  }
  .not-found {
    flex-wrap: wrap;
    &__item {
      width: 100%;
      &:last-child {
        order: -1;
      }

      text-align: center;
    }
    &__number {
      font-size: 200px;
    }
  }
  .tariff-intro-wrap {
    padding: 120px 0 50px;
  }
  .tariff-intro {
    &__title {
      font-size: 40px;
    }
    &__text {
      font-size: 18px;
    }
  }
  .tariff-radio {
    margin: 0 -8px;
    &__label {
      margin: 0 8px;
    }
    &__button {
      width: 200px;
      height: 44px;
      font-size: 16px;
      font-weight: 500;
    }
    &__percent {
      font-size: 14px;
      width: 45px;
      height: 45px;
      transform: translateY(0);
      top: -25px;
      right: -15px;
    }
  }
  .tariff {
    &__block {
      flex-direction: column;
    }
    &__item {
      height: auto;
      margin-bottom: 25px;
      &--special {
        height: auto;
      }
    }
    &__caption {
      margin: 0 auto 25px;
    }
  }
  .compare-wrap {
    padding: 50px 0;
  }
  .compare-block-wrap {
    overflow-x: auto;
  }
  .compare-block {
    min-width: 800px;
    box-shadow: none;
    border-width: 2px;
  }
  .faq-wrap {
    padding: 0 0 50px;
  }
  .faq-list {
    &__question {
      padding: 20px;
    }
    &__answer {
      padding: 20px;
    }
  }
  .intro-instruments-wrap {
    padding: 120px 0 50px;
    &::before {
      left: 10px;
    }
  }
  .intro-instruments {
    &__title {
      font-size: 40px;
    }
    &__text {
      font-size: 18px;
      margin: 0 auto 30px;
    }
    &__list {
      &::after {
        display: none;
      }
    }
  }
  .intro-instruments-image {
    margin-bottom: 0;
    &__border {
      display: none;
    }
    &__block {
      display: none;
    }
    img {
      width: 100%;
    }
  }
  .services-instruments, .advantages-instruments, .how-instruments, .try-instruments {
    &__title {
      font-size: 34px;
      margin-bottom: 40px;
    }
  }
  .content-instruments-wrap, .advantages-instruments-wrap, .how-instruments-wrap, .see-instruments-wrap, .services-instruments-wrap {
    padding: 50px 0;
  }
  .advantages-instruments {
    &__block {
      flex-direction: column;
      margin: 0;
    }
    &__item {
      width: 100%;
      padding: 0;
      margin-bottom: 40px;
    }
    &__icon {
      margin-bottom: 20px;
    }
    &__caption {
      margin-bottom: 15px;
    }
    &__text {
      max-width: 440px;
      margin: 0 auto;
    }
  }
  .how-instruments {
    .save-timeline {
      flex-direction: column;
      text-align: left;
      padding: 0 4vw;
      &__text {
        font-size: 18px;
        text-align: left;
      }
      &--20 {
        .save-timeline__item {
          width: 100%;
        }
      }
      &__item {
        width: 100%;
        margin-bottom: 25px;
        &::before {
          top: 70%;
          height: 100%;
          width: 4px;
          right: 12px;
        }
        &::after {
          top: 70%;
          height: 100%;
          width: 4px;
          right: 12px;
        }
      }
      &__dot {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  .see-instruments {
    flex-direction: column;
    padding-bottom: 0;
    &::before {
      display: none;
    }
    &__images {
      order: 2;
      height: 390px;
    }
    &__info {
      order: 1;
      margin: 0 0 50px;
      text-align: center;
      .theme-button {
        margin: 0 auto;
      }
    }
    .see-instruments-images {
      transform: rotate(0);
    }
    .see-instruments-images--1 {
      left: 0;
      top: 0;
    }
    .see-instruments-images--2 {
      left: 53%;
      top: 0;
    }
    .see-instruments-images--3 {
      left: 75%;
      top: 0;
    }
    .see-instruments-images--4 {
      left: 34%;
      top: 66%;
    }
    .see-instruments-images--5 {
      left: 48%;
      top: 40%;
    }
    .see-instruments-images--6 {
      left: 83%;
      top: 39%;
    }
    .see-instruments-images--7 {
      left: 82%;
      top: 73%;
    }
  }
  .try-instruments-wrap {
    padding: 50px 0 75px;
  }
  .try-instruments {
    &__block {
      flex-direction: column;
      margin: 0;
    }
    &__item {
      width: 100%;
      padding: 0;
      max-width: 460px;
    }
    &__text {
      max-width: 100%;
    }
  }
  .cat-inst-content {
    &__title {
      font-size: 24px;
      max-width: 750px;
      text-align: center;
      margin: 0 auto 30px;
    }
    &__text {
      font-size: 16px;
      max-width: 750px;
      margin: 0 auto 30px;
      text-align: center;
    }
    &__image {
      position: static;
      margin-bottom: 50px;
      text-align: center;
      &.center {
        transform: translateY(0);
      }
    }
    &__block {
      .try-instruments__item {
        width: 50%;
      }
      &--special {
        .try-instruments__item {
          &:first-child {
            width: 50%;
          }
        }
      }
    }
  }
  .cat-inst-content-wrap {
    padding: 50px 0;
    &.last {
      padding-bottom: 75px;
    }
  }
}
@media all and (max-width: 767px) {
  .about-intro-wrap {
    padding: 75px 0 50px;
    &::before {
      display: none;
    }
  }
  .about-intro {
    &__title {
      font-size: 24px;
      margin-bottom: 25px;
    }
    &__content {
      font-size: 14px;
      p {
        margin-bottom: 15px;
      }
      margin-bottom: 15px;
    }
    &__logos {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        margin: 20px 0 !important;
      }
    }
  }
  .about-team {
    &__caption {
      font-size: 16px;
      max-width: 345px;
    }
    &__photo {
      width: 100px;
      height: 100px;
      img {
        max-width: 100%;
      }
    }
    &__item {
      margin: 0 20px 30px;
    }
    &__name {
      font-size: 18px;
      margin: 15px 0 5px;
    }
    &__text {
      font-size: 14px;
    }
  }
  .about-numbers-wrap {
    padding: 25px 0;
  }
  .about-numbers {
    &__block {
      flex-direction: column;
    }
    &__item {
      width: 100%;
      margin: 0 0 20px;
    }
    &__count {
      font-size: 34px;
    }
    &__bold {
      font-size: 16px;
    }
    &__text {
      font-size: 14px;
    }
  }
  .join__form {
    margin-bottom: 50px;
  }
  .try-instruments__gallery--arrow {
    &--prev {
      left: 0;
    }
    &--next {
      right: 0;
    }
  }
  .not-found-wrap {
    padding-top: 100px;
    &::before {
      display: none;
    }
  }
  .not-found {
    flex-wrap: wrap;
    &__number {
      font-size: 130px;
    }
    &__links {
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        margin: 15px 0;
        padding: 10px 40px;
      }
    }
  }
  .tariff-intro-wrap {
    padding: 100px 0 25px;
  }
  .tariff-intro {
    &__title {
      font-size: 24px;
      margin-bottom: 25px;
    }
    &__text {
      max-width: 320px;
      font-size: 14px;
      margin: 0 auto 25px;
    }
  }
  .tariff {
    &__radio {
      margin-bottom: 25px;
    }
    &__item {
      padding: 20px 0;
      width: 100%;
      max-width: 370px;
    }
    &__title {
      font-size: 20px;
      margin-bottom: 10px;
    }
    &__price {
      margin-bottom: 15px;
    }
  }
  .tariff-radio {
    margin: 0;
    flex-direction: column;
    align-items: center;
    &__label {
      margin: 0 auto 20px;
    }
  }
  .compare-wrap {
    padding: 80px 0 25px;
  }
  .compare-block {

    > div {
      padding: 10px;
    }
    > div.compare-block__head {
      padding: 15px 10px;
    }
  }
  .faq-wrap {
    padding: 0 0 25px;
  }
  .faq-list {
    &__question {
      padding: 15px 40px 15px 20px;
      font-size: 14px;
      &::after {
        top: 20px;
      }
    }
    &__answer {
      padding: 10px 10px 10px 20px;
      font-size: 14px;
    }
  }
  .intro-instruments-wrap {
    padding: 100px 0 25px;
    &::before {
      left: 5px;
    }
  }
  .intro-instruments {
    &__title {
      font-size: 24px;
      margin-bottom: 25px;
    }
    &__text {
      font-size: 14px;
      margin: 0 auto 30px;
    }
    &__links {
      flex-direction: column;
      margin-bottom: 0;
    }
    &__list {
      ul {
        margin: 0;
        flex-direction: column;
        align-items: center;
        li {
          margin: 0 0 20px;
          font-size: 14px;
          width: 200px;
        }
      }
    }
  }
  .services-instruments, .advantages-instruments, .how-instruments, .try-instruments {
    &__title {
      font-size: 24px;
    }
  }
  .content-instruments-wrap, .advantages-instruments-wrap, .how-instruments-wrap, .see-instruments-wrap {
    padding: 25px 0;
  }
  .content-instruments {
    &__image {
      margin: 0;
    }
    &__info {
      h3 {
        font-size: 18px;
        margin-bottom: 15px;
      }
      p {
        font-size: 14px;
      }
    }
  }
  .content-instruments-image {
    img {
      max-width: 100%;
    }
  }
  .advantages-instruments {
    &__icon {
    }
    &__caption {
      font-size: 18px;
    }
    &__text {
      font-size: 14px;
    }
  }
  .how-instruments {
    .save-timeline {
      &__text {
        font-size: 14px;
        text-align: left;
        padding-right: 20px;
      }
      &__item {
        margin-bottom: 20px;
        min-height: 42px;
        &::before {
          top: 45%;
          height: 100%;
          width: 4px;
          right: 12px;
        }

        &::after {
          top: 45%;
          height: 100%;
          width: 4px;
          right: 12px;
        }
      }
    }
  }
  .see-instruments {
    &__title {
      font-size: 20px;
      margin-bottom: 30px;
    }
    &__text {
      font-size: 16px;
      margin-bottom: 30px;
    }
    &__images {
      width: 100%;
      height: 50vw;
    }
  }
  .try-instruments {
    &__item {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    &__ico {
      margin-right: 0;
    }
    &__caption {
      margin: 15px 0;
    }
    &__link {
      margin-bottom: 20px;
    }
    &__more {
      a {
        font-size: 16px;
      }
    }
  }
  .try-instruments-bullets {
    &__item {
      width: 50%;
    }
  }
  .access {
    &__block {
      align-items: center;
    }
    &__item {
      width: 170px;
      box-sizing: border-box;
      margin-bottom: 5px;
    }
  }
  .cat-inst-content {
    &__title {
      font-size: 20px;
      line-height: 1.3;
      max-width: 750px;
      text-align: center;
      margin: 0 auto 20px;
    }
    &__text {
      font-size: 14px;
      max-width: 750px;
      margin: 0 auto 20px;
      text-align: center;
    }
    &__image {
      position: static;
      margin-bottom: 20px;
      text-align: center;
      &.center {
        transform: translateY(0);
      }
    }
    &__block {
      .try-instruments__item {
        width: 100%;
      }
      &--special {
        .try-instruments__item {
          &:first-child {
            width: 100%;
          }
        }
      }
    }
  }
  .cat-inst-content-wrap {
    padding: 25px 0;
    &.last {
      padding-bottom: 50px;
    }
  }
}







@media all and (max-width: 1000px) {
  .header-nav-close {
    display: block
  }
  .hamburger {
    display: flex;
  }
  .header__block {
    padding: 0 10px;
    padding-right: 70px;
  }
  .header__nav {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    margin: 0 !important;
    height: 100%;
    background-color: #ffffff;
    width: 320px;
    transition: all 0.2s linear;
    will-change: transform;
    transform: translateX(100%);
    &.open {
      transform: translateX(0);
    }
    .navigation {
      display: block;
      padding-top: 40px;
      > li {
        margin-right: 0;
        border-bottom: 1px solid #ccc;
        > a {
          font-size: 20px;
          line-height: 40px;
          padding: 0 15px;
          font-weight: 500;
        }
      }
    }
    .menu-item-has-children > a {
      &::after {
        right: 10px;
      }
    }
    .sub-menu {
      position: static;
      //display: block;
      border: none;
      border-radius: 0;
      padding: 10px 25px;
      &::before, &::after {
        display: none;
      }
      li {
        margin-bottom: 10px;
      }
      a {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .say__title {
    margin-bottom: 25px;
  }
}
@media all and (max-width: 767px) {
  .navigation__links {
    display: flex;
    margin-top: 10px;
    margin-bottom: -20px;
  }
  .say-slider .slick-slide > div {
    box-sizing: border-box;
    padding: 0 15px;
  }
  .access__form .form-theme__border {
    transform: rotate(8deg);
  }
  .tabs__links .slick-slide {
    padding: 0 5px;
    box-sizing: border-box;
  }
}
