.font-face(@alias, @name){
  @font-face {
    font-family: @alias;
    src: url("../../fonts/@{name}.eot");
    src: url("../../fonts/@{name} + .eot?#iefix") format("embedded-opentype"),
  url("../fonts/@{name}.woff") format("woff"),
  url("../fonts/@{name}.woff2") format("woff2"),
  url("../fonts/@{name}.svg") format("svg"),
  url("../fonts/@{name}.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
  }
}

.border-radius(@radius){
  -webkit-border-radius:@radius;
  -moz-border-radius:@radius;
  border-radius:@radius;
}

.box-shadow(@shadow){
  -webkit-box-shadow: @shadow;
  -moz-box-shadow: @shadow;
  box-shadow: @shadow;
}

.flex {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-direction(@direction) {
  -ms-flex-direction: @direction;
  -webkit-flex-direction: @direction;
  flex-direction: @direction;
}
.flex-center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.flex-space-between {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.flex-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.flex-middle {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.flex-bottom {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.flex-right {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.flex(@flex) {
  -ms-flex: @flex;
  -webkit-flex: @flex;
  flex: @flex;
}
.flex-wrap(@wrap) {
  -ms-flex-wrap: @wrap;
  -webkit-flex-wrap: @wrap;
  flex-wrap: @wrap;
}
.flex-order(@order) {
  -ms-flex-order: @order;
  order: @order;
}

