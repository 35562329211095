body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
dl,
dd,
ol,
ul,
fieldset,
form,
menu,
select,
textarea,
input,
button,
optgroup {
	margin: 0;
	padding: 0;
}

ul {
	list-style: none;
}

a {
	background-color: transparent;
}

img,
input,
button {
	border: 0;
}

input {
	border-radius: 0;
}

input,
textarea,
select,
button,
a:active,
a:hover {
	outline: 0;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
	-webkit-appearance: none;
}

input[type="submit"],
button {
	cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	overflow: auto;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}

td,
th {
	padding: 0;
}

svg:not(:root) {
	overflow: hidden;
}

main {
	display: block;
}

.flex {
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

.flex-center {
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-space-between {
	-ms-flex-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}

.flex-space-around {
	-ms-flex-pack: distribute;
	-webkit-justify-content: space-around;
	justify-content: space-around;
}

.flex-middle {
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.flex-bottom {
	-ms-flex-align: end;
	-webkit-align-items: flex-end;
	align-items: flex-end;
}

.flex-right {
	-ms-flex-pack: end;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
}

/* Slider */

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;
}

.slick-list:focus {
	outline: none;
}

.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.slick-track:before,
.slick-track:after {
	content: "";
	display: table;
}

.slick-track:after {
	clear: both;
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	display: none;
}

[dir="rtl"] .slick-slide {
	float: right;
}

.slick-slide img {
	display: block;
}

.slick-slide.slick-loading img {
	display: none;
}

.slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
	display: none;
}

/* Slider */

.slick-loading .slick-list {
	background: #fff url('../images/ajax-loader.gif') center center no-repeat;
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: 20px;
	width: 20px;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
}

.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
	outline: none;
	background: transparent;
	color: transparent;
}

.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
	opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
	opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
	font-family: "slick";
	font-size: 20px;
	line-height: 1;
	color: white;
	opacity: 0.75;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; /* Icons */
}

@font-face {
	font-family: 'slick';
	font-weight: normal;
	font-style: normal;
	src: url('../fonts/slick.eot');
	src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'), url('../fonts/slick.woff') format('woff'), url('../fonts/slick.ttf') format('truetype'), url('../fonts/slick.svg#slick') format('svg');
}

.slick-prev {
	left: -25px;
}

[dir="rtl"] .slick-prev {
	left: auto;
	right: -25px;
}

.slick-prev:before {
	content: "←";
}

[dir="rtl"] .slick-prev:before {
	content: "→";
}

.slick-next {
	right: -25px;
}

[dir="rtl"] .slick-next {
	left: -25px;
	right: auto;
}

.slick-next:before {
	content: "→";
}

[dir="rtl"] .slick-next:before {
	content: "←";
}

/* Dots */

.slick-dotted .slick-slider {
	margin-bottom: 30px;
}

.slick-dots {
	position: absolute;
	bottom: -25px;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
}

.slick-dots li {
	position: relative;
	display: inline-block;
	height: 20px;
	width: 20px;
	margin: 0 5px;
	padding: 0;
	cursor: pointer;
}

.slick-dots li button {
	border: 0;
	background: transparent;
	display: block;
	height: 20px;
	width: 20px;
	outline: none;
	line-height: 0px;
	font-size: 0px;
	color: transparent;
	padding: 5px;
	cursor: pointer;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
	outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
	opacity: 1;
}

.slick-dots li button:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "•";
	width: 20px;
	height: 20px;
	font-family: "slick";
	font-size: 6px;
	line-height: 20px;
	text-align: center;
	color: black;
	opacity: 0.25;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
	color: black;
	opacity: 0.75;
}

/*
 * CSS Styles that are needed by jScrollPane for it to operate correctly.
 *
 * Include this stylesheet in your site or copy and paste the styles below into your stylesheet - jScrollPane
 * may not operate correctly without them.
 */

.jspContainer {
	overflow: hidden;
	position: relative;
}

.jspPane {
	position: absolute;
}

.jspVerticalBar {
	background: red;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	width: 16px;
}

.jspHorizontalBar {
	background: red;
	bottom: 0;
	height: 16px;
	left: 0;
	position: absolute;
	width: 100%;
}

.jspCap {
	display: none;
}

.jspHorizontalBar .jspCap {
	float: left;
}

.jspTrack {
	background: #dde;
	position: relative;
}

.jspDrag {
	background: #bbd;
	cursor: pointer;
	left: 0;
	position: relative;
	top: 0;
}

.jspHorizontalBar .jspTrack,
.jspHorizontalBar .jspDrag {
	float: left;
	height: 100%;
}

.jspArrow {
	background: #50506d;
	cursor: pointer;
	direction: ltr;
	display: block;
	margin: 0;
	padding: 0;
	text-indent: -20000px;
}

.jspDisabled {
	background: #80808d;
	cursor: default;
}

.jspVerticalBar .jspArrow {
	height: 16px;
}

.jspHorizontalBar .jspArrow {
	float: left;
	height: 100%;
	width: 16px;
}

.jspVerticalBar .jspArrow:focus {
	border: 1px solid red;
	outline: none;
}

.jspCorner {
	background: #eeeef4;
	float: left;
	height: 100%;
}

/* Yuk! CSS Hack for IE6 3 pixel bug :( */

* html .jspCorner {
	margin: 0 -3px 0 0;
}

html {
	height: 100%;
}

body {
	margin: 0;
	background: #fff;
	color: #10100F;
	min-height: 100%;
	font-size: 16px;
	font-weight: 400;
	height: auto;
	font-family: 'Montserrat', Arial, sans-serif;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: none;
	box-sizing: border-box;
	overflow-x: hidden;
}

body.open-mobile-menu {
	overflow: hidden;
}

input {
	font-family: 'Montserrat', Arial, sans-serif;
}

.container {
	width: 100%;
	max-width: 1260px;
	padding: 0 10px;
	margin: 0 auto;
	box-sizing: border-box;
}

.theme-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 331px;
	height: 54px;
	font-size: 18px;
	font-weight: 700;
	line-height: 20px;
	color: #ffffff;
	box-sizing: border-box;
	border-radius: 30px;
	background-color: #29CB7E;
	text-decoration: none;
	transform: scale(1);
	transition: all 200ms ease-in-out;
	will-change: auto;
}

.theme-button:hover {
	transform: scale(1.01);
}

.theme-button:active {
	background-color: #219E63;
	transform: scale(1.01);
}

.theme-button-small {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 34px;
	font-size: 16px;
	line-height: 20px;
	color: #29CB7E;
	box-sizing: border-box;
	border: 2px solid #29CB7E;
	border-radius: 30px;
	text-decoration: none;
	transform: scale(1);
	transition: all 200ms ease-in-out;
	will-change: auto;
}

.theme-button-small:hover {
	border-color: #29CB7E;
	background-color: #29CB7E;
	color: #fff;
	transform: scale(1.05);
}

.theme-button-small:active {
	border-color: #219E63;
	background-color: #219E63;
	transform: scale(1.05);
}

.main {
	padding: 140px 0;
}

.main-title {
	font-size: 64px;
	font-weight: normal;
	font-family: "Arimo", Arial, sans-serif;
	line-height: 72px;
	text-align: center;
	margin-bottom: 50px;
}

.header {
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100px;
	background-color: #fff;
	transition: all 0.2s linear;
	will-change: auto;
}

.header.scroll {
	height: 70px;
}

.header.scroll .header__nav {
	margin: 0 2vw;
}

.header.scroll .lang-dropdown {
	margin-left: 2vw;
}

.header__block {
	height: 100%;
	width: 100%;
	padding: 0 30px;
	margin: 0 auto;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header__nav {
	flex: 1 1 auto;
	margin: 0 4vw;
	will-change: auto;
	transition: all 0.2s linear;
}

.header__control {
	display: flex;
	align-items: center;
}

.header .navigation {
	display: flex;
}

.header .navigation > li {
	margin-right: 20px;
}

.header .navigation > li:last-child {
	margin-right: 0;
}

.header .navigation a {
	display: block;
	font-size: 16px;
	line-height: 20px;
	color: #10100F;
	text-decoration: none;
	white-space: nowrap;
}

.header .navigation a:hover {
	color: #29CB7E;
}

.header .sub-menu {
	position: absolute;
	right: -32px;
	top: 33px;
	display: none;
	min-width: 140px;
	padding: 15px 25px;
	border: 1px solid #29CB7E;
	border-radius: 16px;
	box-sizing: border-box;
	background: #ffffff;
}

.header .sub-menu.open {
	display: block;
}

.header .sub-menu::before {
	content: '';
	position: absolute;
	top: -20px;
	right: 27px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 8px 20px 8px;
	border-color: transparent transparent #29CB7E transparent;
}

.header .sub-menu::after {
	content: '';
	position: absolute;
	top: -18px;
	right: 27px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 8px 20px 8px;
	border-color: transparent transparent #fff transparent;
}

.header .sub-menu > li {
	margin-bottom: 5px;
	font-size: 14px;
	line-height: 17px;
}

.header .sub-menu > li:last-child {
	margin-bottom: 0;
}

.header .sub-menu > li > a {
	white-space: nowrap;
	font-size: 14px;
	line-height: 17px;
	color: #10100F;
}

.header .menu-item-has-children {
	position: relative;
}

.header .menu-item-has-children > a {
	padding-right: 12px;
	position: relative;
	color: #10100F;
	text-decoration: none;
}

.header .menu-item-has-children > a.open {
	color: #29CB7E;
}

.header .menu-item-has-children > a.open::after {
	border-color: #29CB7E transparent transparent transparent;
}

.header .menu-item-has-children > a::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	border-style: solid;
	border-width: 7px 4px 0 4px;
	border-color: rgba(16, 16, 15, 0.5) transparent transparent transparent;
}

.header .menu-item-has-children > a:hover {
	color: #29CB7E;
}

.header-link {
	margin-left: 20px;
}

.header-link:first-of-type {
	margin-left: 0;
}

.header-link.sign-in {
	border-color: transparent;
	color: #10100F;
}

.header-link.sign-in:hover {
	color: #fff;
}

.lang-dropdown {
	margin-left: 4vw;
	transition: all 0.2s linear;
	will-change: auto;
}

.lang-dropdown li {
	cursor: pointer;
	position: relative;
}

.lang-dropdown li:hover {
	color: #29CB7E;
}

.lang-dropdown li.active::before {
	content: url("../images/menu-arrow.svg");
	position: absolute;
	left: -15px;
	top: 0;
	line-height: 14px;
}

.intro-wrap {
	padding: 220px 0 220px;
	position: relative;
	overflow: hidden;
}

.intro-wrap__komondor {
	position: absolute;
	bottom: 0px;
	left: 15vw;
	opacity: 0;
}

.intro-wrap__line {
	position: absolute;
	left: 15vw;
	top: 0;
	width: 2px;
	height: 80%;
	transform: translateY(-100vw);
	background-color: #FFC90B;
}

.intro {
	display: flex;
	position: relative;
}

.intro__image {
	position: absolute;
	top: 50%;
	right: -125px;
	transform: translateY(-50%);
}

.intro__title {
	font-family: "Arimo", sans-serif;
	font-size: 64px;
	font-weight: 700;
	line-height: 116%;
	width: 100%;
	max-width: 600px;
	margin-bottom: 5px;
}

.intro__sub {
	font-size: 22px;
	font-weight: 500;
	line-height: 120%;
	width: 100%;
	max-width: 415px;
	margin-bottom: 30px;
}

.intro__action {
	display: flex;
	align-items: flex-end;
}

.intro__button {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.intro__label {
	font-size: 14px;
	line-height: 17px;
	margin-bottom: 10px;
}

.intro__text {
	height: 54px;
	display: flex;
	align-items: center;
	margin-left: 15px;
	font-size: 14px;
	line-height: 17px;
	width: 100%;
	max-width: 192px;
}

.intro-laptop {
	position: relative;
}

.intro-laptop.hover:hover .intro-laptop__block {
	transform: translateY(0) rotate(22deg) !important;
}

.intro-laptop.hover:hover .intro-laptop__border {
	transform: rotate(0) !important;
}

.intro-laptop.hover .intro-laptop__block,
.intro-laptop.hover .intro-laptop__border {
	transition: all 0.2s ease-in-out;
}

.intro-laptop__block {
	position: absolute;
	top: -18%;
	right: 12%;
	width: 60%;
	height: 135%;
	background-color: #FFC90B;
	transform: translateY(100vw);
	opacity: 0;
}

.intro-laptop__border {
	position: absolute;
	top: -18%;
	right: 12%;
	width: 60%;
	height: 135%;
	border: 2px solid #29CB7E;
	box-sizing: border-box;
	opacity: 0;
}

.intro-laptop__img {
	position: relative;
	z-index: 3;
	max-width: 680px;
	border: 1px solid #333;
	border-top: 0;
	border-radius: 15px;
}

.helps-wrap {
	margin: 160px 0;
}

.helps__block {
	width: 100%;
	display: flex;
	min-width: 0;
	min-height: 0;
}

.helps__instruments {
	width: 365px;
}

.helps__tabs {
	width: calc(100% - 365px);
	padding-left: 70px;
	box-sizing: border-box;
}

.instruments {
	width: 100%;
	height: 534px;
	overflow: auto;
	outline: none;
}

.instruments__items {
	padding: 20px 0 20px 40px;
	width: 312px;
	box-sizing: border-box;
}

.instruments__title {
	font-size: 22px;
	font-weight: 500;
	line-height: 28px;
	margin-bottom: 25px;
	color: #10100F;
	position: relative;
}

.instruments__title::before {
	content: url("../images/instruments-title.svg");
	position: absolute;
	left: -30px;
	top: 0;
	opacity: 0;
	transform: translateX(-20px);
	transition: all 0.2s linear;
}

.instruments__title.hover {
	color: #29CB7E;
}

.instruments__title.hover ~ a {
	color: #29CB7E;
}

.instruments__title.hover::before {
	opacity: 1;
	transform: translateX(0);
}

.instruments__title a {
	font-size: inherit;
	font-family: inherit;
	color: inherit;
	text-decoration: none;
}

.instruments__text {
	font-size: 16px;
	line-height: 20px;
	color: #10100F;
	margin-bottom: 10px;
}

.instruments__link {
	display: inline-block;
	font-size: 18px;
	line-height: 22px;
	color: #10100F;
	text-decoration: none;
}

.instruments__link:hover {
	color: #29CB7E;
}

.tabs {
	min-width: 0;
	min-height: 0;
	width: 100%;
	flex-direction: column;
}

.tabs__links {
	margin-bottom: 40px;
}

.tabs__links .tabs__item {
	display: flex !important;
	justify-content: center;
	align-items: center;
	width: 200px !important;
	height: 38px;
	box-sizing: border-box;
	padding-bottom: 4px;
	position: relative;
	margin: 0 22px;
	font-size: 22px;
	font-weight: 500;
	line-height: 28px;
	color: #10100F;
	text-decoration: none;
	cursor: pointer;
}

.tabs__links .tabs__item::before {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 4px;
	background-color: #FFF0BB;
	border-radius: 2px;
}

.tabs .slick-current .tabs__item {
	pointer-events: none;
}

.tabs .slick-current .tabs__item::before {
	background-color: #FFC90B;
}

.tabs__contents {
	position: relative;
}

.tabs__contents img {
	position: static;
	display: none;
	left: 50%;
	top: 0;
	width: auto !important;
	height: auto;
	max-width: 100%;
	border: 1px solid #29CB7E;
	box-sizing: border-box;
}

.jspVerticalBar {
	width: 4px !important;
	border-radius: 2px;
	overflow: hidden;
	background: transparent;
}

.jspTrack {
	background-color: #FFF0BB;
	border-radius: 2px;
}

.jspDrag {
	background-color: #FFC90B;
	border-radius: 2px;
}

.join-wrap {
	margin: 160px 0;
}

.join {
	display: flex;
}

.join__form {
	flex: 0 0 auto;
}

.join__info {
	flex: 1 1 auto;
}

.form-theme {
	position: relative;
	width: 552px;
	height: 501px;
	padding: 50px;
	box-sizing: border-box;
}

.form-theme__border {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -2;
	left: 0;
	top: 0;
	box-sizing: border-box;
	border: 2px solid #29CB7E;
	transform: rotate(-15deg);
}

.form-theme__block {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
	left: 0;
	top: 0;
	background-color: #FFC90B;
	transform: translateY(200%);
	opacity: 0;
}

.form-theme__title {
	font-family: "Arimo", Arial, sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 34px;
	color: #10100F;
	text-align: center;
	margin-bottom: 36px;
}

.form-theme__input {
	position: relative;
	margin-bottom: 20px;
	text-align: center;
}

.form-theme__input option {
	color: #10100F;
}

.form-theme__input input:not([type='checkbox']),
.form-theme__input select {
	display: block;
	width: 100%;
	height: 50px;
	padding: 0 40px;
	border: 1px solid #FFC90B;
	box-sizing: border-box;
	border-radius: 30px;
	font-size: 18px;
	font-family: "Montserrat", Arial, sans-serif;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
}

.form-theme__input input:not([type='checkbox'])::-webkit-input-placeholder,
.form-theme__input select::-webkit-input-placeholder {
	color: #cccccc;
}

.form-theme__input input:not([type='checkbox'])::-moz-placeholder,
.form-theme__input select::-moz-placeholder {
	color: #cccccc;
}

.form-theme__input input:not([type='checkbox']):-ms-input-placeholder,
.form-theme__input select:-ms-input-placeholder {
	color: #cccccc;
}

.form-theme__input input:not([type='checkbox']):-moz-placeholder,
.form-theme__input select:-moz-placeholder {
	color: #cccccc;
}

.form-theme__input input:not([type='checkbox']):focus,
.form-theme__input select:focus {
	border-color: #29CB7E;
}

.form-theme__input input:not([type='checkbox']):focus::-webkit-input-placeholder,
.form-theme__input select:focus::-webkit-input-placeholder {
	color: #10100F;
}

.form-theme__input input:not([type='checkbox']):focus::-moz-placeholder,
.form-theme__input select:focus::-moz-placeholder {
	color: #10100F;
}

.form-theme__input input:not([type='checkbox']):focus:-ms-input-placeholder,
.form-theme__input select:focus:-ms-input-placeholder {
	color: #10100F;
}

.form-theme__input input:not([type='checkbox']):focus:-moz-placeholder,
.form-theme__input select:focus:-moz-placeholder {
	color: #10100F;
}

.form-theme__checkbox {
	text-align: center;
	display: inline-block;
	margin: 0 auto;
	color: #10100F;
	-webkit-appearance: none;
	appearance: none;
}

.form-theme__checkbox input {
	display: none;
}

.form-theme__checkbox input:checked + .checkbox-text::after {
	display: block;
}

.form-theme__checkbox .checkbox-text {
	position: relative;
	display: inline-block;
	padding-left: 38px;
	font-size: 16px;
	line-height: 20px;
	color: #10100F;
	cursor: pointer;
}

.form-theme__checkbox .checkbox-text::before {
	content: '';
	position: absolute;
	left: 0;
	top: -5px;
	width: 30px;
	height: 30px;
	border-radius: 100%;
	border: 1px solid #29CB7E;
	box-sizing: border-box;
}

.form-theme__checkbox .checkbox-text::after {
	content: '';
	display: none;
	position: absolute;
	left: 14px;
	top: -12px;
	width: 12px;
	height: 28px;
	box-sizing: border-box;
	border-right: 1px solid #29CB7E;
	border-bottom: 1px solid #29CB7E;
	transform: rotate(45deg);
}

.form-theme__checkbox a {
	color: #10100F;
	text-decoration: none;
}

.form-theme__button {
	margin-top: 40px;
}

.form-theme__button input {
	margin: 0 auto;
	background: #29CB7E !important;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.form-theme__row {
	text-align: center;
}

.form-theme__row > * {
	margin: 0 10px;
}

.form-theme a {
	font-size: 16px;
	line-height: 20px;
	color: #10100F;
	text-decoration: none;
	border-bottom: 1px solid #29CB7E;
}

.form-theme a:hover {
	border-color: transparent;
}

.form-theme__caption {
	margin-top: 20px;
	text-align: center;
	font-size: 16px;
	line-height: 20px;
}

.join__info {
	margin-left: 100px;
	margin-top: 100px;
}

.join__title {
	font-family: "Arimo", Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 32px;
	line-height: 38px;
	margin-bottom: 40px;
}

.join__sub-title {
	font-weight: 500;
	font-size: 22px;
	line-height: 26px;
}

.say-wrap {
	margin: 160px 0;
}

.say-slider__item {
	width: 624px;
	height: 320px;
	transition: 0.3s all linear;
	margin-bottom: 40px;
	box-sizing: border-box;
}

.say-slider__content {
	width: 608px;
	height: 240px;
	position: relative;
	box-sizing: border-box;
	border: 1px solid #10100F;
	border-radius: 180px;
	padding: 50px 50px 50px 80px;
	font-size: 16px;
	line-height: 20px;
	margin: 0 20px 20px;
	transition: 0.3s all linear;
}

.say-slider__content::before {
	content: '“';
	position: absolute;
	left: 0;
	top: 0;
	font-family: "Arimo", Arial, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 200px;
	line-height: 230px;
	color: rgba(16, 16, 15, 0.1);
	transition: 0.3s all linear;
}

.say-slider__content::after {
	content: '';
	position: absolute;
	left: 22px;
	bottom: -13px;
	width: 26px;
	height: 26px;
	border-radius: 100%;
	border: 1px solid #E1E1E1;
	box-sizing: border-box;
	transition: 0.3s all linear;
}

.say-slider__bio {
	display: flex;
	align-items: center;
}

.say-slider__avatar {
	position: relative;
	width: 60px;
	height: 60px;
	border-radius: 100%;
	overflow: hidden;
	margin-right: 20px;
}

.say-slider__avatar img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: auto;
}

.say-slider__name {
	font-size: 24px;
	line-height: 29px;
	font-weight: 500;
}

.say-slider__post {
	font-size: 18px;
	line-height: 22px;
}

.slick-slide {
	outline: none;
}

.slick-current .say-slider__item {
	transform: translateY(40px);
}

.slick-current .say-slider__content {
	border-color: #29CB7E;
}

.slick-current .say-slider__content::before {
	left: -15px;
	top: -20px;
	color: #FFC90B;
}

.slick-current .say-slider__content::after {
	border-color: #29CB7E;
}

.say {
	position: relative;
}

.say-slider-arrow {
	position: absolute;
	top: 50%;
	width: 30px;
	height: 30px;
	margin-top: -15px;
	background: url("../images/slider-arrow.svg") no-repeat center center;
	cursor: pointer;
}

.say-slider-previous {
	left: -150px;
}

.say-slider-next {
	right: -150px;
	transform: rotate(180deg);
}

.partners-wrap {
	margin: 160px 0;
	position: relative;
}

.partners-wrap .container {
	max-width: 1260px;
}

.partners-wrap__line {
	position: absolute;
	left: 15vw;
	top: -15%;
	width: 2px;
	height: 0;
	background-color: #FFC90B;
}

.partners-wrap__border-right {
	position: absolute;
	right: 15vw;
	top: calc(50% + 70px);
	height: 0;
	width: 1px;
	background-color: #29CB7E;
}

.partners-wrap__border-bottom {
	position: absolute;
	right: 15vw;
	bottom: -70px;
	height: 1px;
	width: 0;
	max-width: 900px;
	background-color: #29CB7E;
}

.partners-block__item {
	position: relative;
	display: grid;
	grid-template-columns: 20% 20% 20% 20% 20%;
	justify-items: center;
	align-items: center;
}

.partners-block__item img {
	display: block;
	width: auto;
	max-width: 250px;
	margin: 0 auto;
}

.save-wrap {
	margin: 160px 0;
}

.save-block {
	display: flex;
	align-items: center;
}

.save-block__content {
	flex: 0 0 580px;
}

.save-block__content h3 {
	font-weight: 500;
	font-size: 20px;
	line-height: 139%;
}

.save-block__content p {
	font-size: 16px;
	line-height: 139%;
	margin-bottom: 30px;
}

.save-block__content ul {
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.save-block__content ul li {
	position: relative;
	padding-left: 30px;
	font-weight: 500;
	font-size: 20px;
	line-height: 139%;
	cursor: pointer;
}

.save-block__content ul li a {
	color: inherit;
	text-decoration: none;
}

.save-block__content ul li::before {
	content: '';
	width: 20px;
	height: 20px;
	background: url("../images/menu-arrow.svg") no-repeat center;
	background-size: 80%;
	position: absolute;
	left: 0;
	top: 6px;
	line-height: 14px;
	opacity: 0.3;
}

.save-block__content ul li.active {
	color: #29CB7E;
}

.save-block__content ul li.active::before {
	opacity: 1;
}

.save-block__image {
	flex: 1 1 auto;
	margin-left: 70px;
}

.save-timeline {
	display: flex;
	justify-content: space-between;
	text-align: center;
	max-width: 1360px;
	margin: 60px auto 0;
}

.save-timeline__item {
	width: 20%;
	position: relative;
}

.save-timeline__item:last-child::before,
.save-timeline__item:last-child::after {
	display: none;
}

.save-timeline__item::before {
	content: '';
	position: absolute;
	top: 8px;
	right: calc(-50% + 28px);
	width: calc(100% - 56px);
	height: 4px;
	box-sizing: border-box;
	border-radius: 2px;
	border: 1px solid #29CB7E;
}

.save-timeline__item::after {
	content: '';
	position: absolute;
	top: 8px;
	right: calc(-50% + 28px);
	width: calc(100% - 56px);
	height: 4px;
	box-sizing: border-box;
	border-radius: 2px;
	background-color: #29CB7E;
}

.save-timeline__dot {
	width: 28px;
	height: 16px;
	border: 1px solid #FFC90B;
	box-sizing: border-box;
	border-radius: 8px;
	position: relative;
	overflow: hidden;
	margin: 0 auto 24px;
}

.save-timeline__dot::before {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background: #FFC90B;
}

.save-timeline__title {
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 5px;
}

.save-timeline__text {
	font-size: 14px;
	line-height: 17px;
}

.access-wrap {
	padding-bottom: 160px;
	overflow: hidden;
}

.access__title {
	font-weight: 400;
	margin-bottom: 20px;
}

.access__block {
	display: flex;
	justify-content: center;
	margin-bottom: 130px;
}

.access__item {
	font-weight: 500;
	font-size: 22px;
	line-height: 26px;
	padding-left: 38px;
	position: relative;
	margin: 0 40px;
}

.access__item::before {
	content: '';
	position: absolute;
	left: 0;
	top: 6px;
	width: 28px;
	height: 16px;
	border-radius: 8px;
	background-color: #FFC90B;
}

.access__form {
	display: flex;
	justify-content: center;
}

.access__form .form-theme {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.access__form .form-theme__border {
	transform: rotate(15deg);
}

.footer-wrap {
	padding: 50px 0 30px;
	background-color: #29CB7E;
}

.footer-wrap .container {
	max-width: 1260px;
}

.footer__top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;
}

.footer__contacts {
	display: flex;
	flex-direction: column;
	text-align: right;
}

.footer__contacts a {
	font-size: 16px;
	line-height: 20px;
	color: #10100F;
	margin-bottom: 5px;
	text-decoration: none;
}

.footer__contacts a:last-child {
	margin-bottom: 0;
}

.footer__block {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}

.footer__navigation {
	display: flex;
}

.footer__item:last-child {
	margin-right: 0;
}

.footer__item > li {
	margin-bottom: 10px;
}

.footer__item > li > a {
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
}

.footer__item > li.menu-item-has-children > a {
	margin-bottom: 10px;
	display: inline-block;
	font-weight: 500;
	font-size: 16px;
}

.footer__item > li.menu-item-has-children ul {
	margin-top: -5px;
	margin-bottom: 10px;
}

.footer__item > li.menu-item-has-children ul li {
	margin-bottom: 5px;
}

.footer__item > li.menu-item-has-children ul li > a {
	font-size: 14px;
	line-height: 17px;
}

.footer__title {
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 10px;
}

.footer__socials a {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 215px;
	height: 50px;
	padding: 5px 23px;
	border: 1px solid #10100F;
	box-sizing: border-box;
	border-radius: 30px;
	margin-bottom: 20px;
	text-align: right;
}

.footer a {
	color: #10100F;
	text-decoration: none;
}

.footer a:hover {
	text-decoration: underline;
}

.footer__buttons {
	display: flex;
	align-items: center;
	margin-bottom: 36px;
	margin-left: 20px;
}

.footer__buttons a {
	display: inline-block;
}

.footer__sign-in {
	margin-right: 40px;
}

.footer__sign-up {
	padding: 5px 20px;
	border: 2px solid #10100F;
	box-sizing: border-box;
	border-radius: 30px;
}

.footer__label {
	margin-left: 20px;
	margin-bottom: 14px;
}

.footer__input {
	display: flex;
}

.footer__input input:not([type=submit]) {
	width: 300px;
	height: 50px;
	box-sizing: border-box;
	border: 2px solid #fff;
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
	background-color: transparent;
	font-size: 16px;
	line-height: 20px;
	color: #ffffff;
	padding: 0 40px;
}

.footer__input input:not([type=submit])::-webkit-input-placeholder {
	color: #ffffff;
}

.footer__input input:not([type=submit])::-moz-placeholder {
	color: #ffffff;
}

.footer__input input:not([type=submit]):-ms-input-placeholder {
	color: #ffffff;
}

.footer__input input:not([type=submit]):-moz-placeholder {
	color: #ffffff;
}

.footer__input input[type=submit] {
	width: 110px;
	height: 50px;
	box-sizing: border-box;
	background-color: #ffffff;
	text-align: center;
	border-top-right-radius: 30px;
	border-bottom-right-radius: 30px;
	font-size: 16px;
	line-height: 20px;
	color: #29CB7E;
}

.footer__bot {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;
}

.footer__links a {
	margin-right: 100px;
}

.footer__copy {
	text-align: center;
}

.footer__copy a {
	text-decoration: underline;
}

.footer__copy a:hover {
	text-decoration: none;
}

.header__overlay {
	display: none;
	position: fixed;
	z-index: 99;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
}

.header__overlay.open {
	display: block;
}

.hamburger {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	flex-direction: column;
	justify-content: space-between;
	width: 45px;
	height: 45px;
	box-sizing: border-box;
	padding: 10px 5px;
	display: none;
	cursor: pointer;
}

.hamburger__line {
	height: 3px;
	width: 100%;
	background-color: #10100F;
}

.header-nav-close {
	display: none;
	position: absolute;
	top: 12px;
	right: 12px;
	width: 30px;
	height: 30px;
}

.header-nav-close::before {
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	width: 100%;
	height: 2px;
	background-color: #cccccc;
	transform: translate(-50%, -50%) rotate(45deg);
}

.header-nav-close::after {
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	width: 100%;
	height: 2px;
	background-color: #cccccc;
	transform: translate(-50%, -50%) rotate(-45deg);
}

.intro-instruments-wrap {
	padding: 150px 0 75px;
	position: relative;
}

.intro-instruments-wrap::before {
	content: '';
	position: absolute;
	left: 82px;
	top: 0;
	width: 1px;
	height: 60%;
	background-color: #FFC90B;
}

.intro-instruments__title {
	font-family: Arimo;
	font-weight: bold;
	font-size: 60px;
	line-height: 1.1;
	text-align: center;
	color: #10100F;
	max-width: 1240px;
	margin: 0 auto 40px;
}

.intro-instruments__text {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 1.5;
	text-align: center;
	max-width: 1024px;
	margin: 0 auto 50px;
}

.intro-instruments__links {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 30px;
}

.intro-instruments__links a {
	margin: 0 15px 30px;
}

.intro-instruments__download {
	background-color: transparent;
	border: 2px solid #29CB7E;
	color: #29CB7E;
}

.intro-instruments__download::before {
	content: '';
	width: 28px;
	height: 28px;
	background: url('../images/google.svg') no-repeat center;
	background-size: contain;
	margin-right: 20px;
}

.intro-instruments__image {
	display: flex;
	justify-content: center;
	align-content: center;
}

.intro-instruments__list {
	padding: 20px 0;
	position: relative;
}

.intro-instruments__list::after {
	content: '';
	position: absolute;
	right: 0;
	bottom: 0;
	width: 80%;
	height: 130%;
	border-right: 1px solid #29CB7E;
	border-bottom: 1px solid #29CB7E;
}

.intro-instruments__list ul {
	margin-bottom: 30px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -50px;
}

.intro-instruments__list ul li {
	position: relative;
	padding-left: 30px;
	font-weight: 500;
	font-size: 20px;
	line-height: 139%;
	width: 260px;
	box-sizing: border-box;
	margin: 0 50px 50px;
}

.intro-instruments__list ul li::before {
	content: '';
	width: 20px;
	height: 20px;
	background: url("../images/menu-arrow.svg") no-repeat center;
	background-size: 80%;
	position: absolute;
	left: 0;
	top: 6px;
	line-height: 14px;
	opacity: 1;
}

.intro-instruments-image {
	position: relative;
	margin-bottom: 100px;
}

.intro-instruments-image img {
	display: block;
}

.intro-instruments-image__border {
	position: absolute;
	z-index: -2;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 296px;
	border: 2px solid #29CB7E;
	box-sizing: border-box;
	transform: rotate(0deg) translateX(0);
	transition: 300ms transform 200ms ease-in-out, 200ms width 0ms ease-in-out, 200ms left 0ms ease-in-out, 200ms bottom 0ms ease-in-out;
}

.intro-instruments-image__block {
	position: absolute;
	z-index: -1;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 296px;
	background: #FFC90B;
	transition: all 200ms ease-in-out;
}

.intro-instruments-image:hover .intro-instruments-image__border {
	transform: rotate(0) translateX(0) !important;
	transition: all 200ms ease-in-out !important;
}

.intro-instruments-image:hover .intro-instruments-image__block {
	transform: rotate(30deg) translateX(-140px) !important;
	transition: all 200ms ease-in-out !important;
}

.intro-instruments-image.hover .intro-instruments-image__border {
	transition: all 200ms ease-in-out !important;
}

.intro-instruments-image.animate .intro-instruments-image__border {
	width: 887px;
	left: -152px;
	bottom: -53px;
	transform: rotate(30deg) translateX(-140px);
}

.intro-instruments-image.animate .intro-instruments-image__block {
	width: 999px;
	left: -152px;
	bottom: -53px;
}

.content-instruments-wrap {
	padding: 100px 0 40px;
}

.content-instruments__block {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 -30px 60px;
}

.content-instruments__info {
	width: 100%;
	margin: 0 30px;
	max-width: 920px;
}

.content-instruments__info h3 {
	font-family: Montserrat;
	font-weight: 500;
	font-size: 20px;
	line-height: 1.5;
	color: #10100F;
	margin-bottom: 25px;
}

.content-instruments__info p {
	font-family: Montserrat;
	font-size: 16px;
	line-height: 1.5;
	color: #10100F;
	width: 100%;
	max-width: 870px;
}

.content-instruments__image {
	margin: 0 30px;
	padding: 25px;
	box-sizing: border-box;
}

.content-instruments__image img {
	display: block;
	filter: drop-shadow(-4px -4px 8px rgba(0, 0, 0, 0.15));
}

.content-instruments-image {
	position: relative;
}

.content-instruments-image::before {
	content: '';
	position: absolute;
	z-index: -1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #FFF0BB;
	transition: all 200ms ease-in-out;
}

.content-instruments-image::after {
	content: '';
	position: absolute;
	z-index: -1;
	right: 0;
	bottom: 0;
	width: 90%;
	height: 80%;
	border-right: 1px solid #29CB7E;
	border-bottom: 1px solid #29CB7E;
	box-sizing: border-box;
	transition: all 200ms ease-in-out;
}

.content-instruments-image.animate::before {
	left: -25px;
	top: -25px;
}

.content-instruments-image.animate::after {
	right: -25px;
	bottom: -25px;
}

.services-instruments-wrap {
	padding: 75px 0;
}

.services-instruments__title {
	font-family: Arimo;
	font-size: 60px;
	line-height: 1.1;
	text-align: center;
	color: #10100F;
	margin-bottom: 70px;
}

.services-instruments__block img {
	width: 100%;
}

.advantages-instruments-wrap {
	padding: 75px 0;
}

.advantages-instruments__title {
	font-family: Arimo;
	font-weight: 400;
	font-size: 60px;
	line-height: 1.1;
	text-align: center;
	color: #10100F;
	margin-bottom: 70px;
}

.advantages-instruments__block {
	display: flex;
	margin: 0 -20px;
}

.advantages-instruments__item {
	width: 33.3333%;
	padding: 0 20px;
}

.advantages-instruments__icon {
	height: 110px;
	text-align: center;
	margin-bottom: 35px;
}

.advantages-instruments__caption {
	font-family: Montserrat;
	font-weight: 500;
	font-size: 20px;
	line-height: 1.5;
	text-align: center;
	color: #10100F;
	margin-bottom: 25px;
}

.advantages-instruments__text {
	font-family: Montserrat;
	font-size: 16px;
	line-height: 1.5;
	text-align: center;
	color: #10100F;
}

.how-instruments-wrap {
	padding: 75px 0;
}

.how-instruments__title {
	font-family: Arimo;
	font-weight: 400;
	font-size: 66px;
	line-height: 1.1;
	text-align: center;
	color: #10100F;
	margin-bottom: 100px;
}

.how-instruments .save-timeline {
	max-width: 100%;
}

.how-instruments .save-timeline__item {
	width: 16.667%;
	padding: 0 10px;
	box-sizing: border-box;
}

.how-instruments .save-timeline__text {
	font-family: Montserrat;
	font-size: 16px;
	line-height: 1.5;
	text-align: center;
}

.how-instruments .save-timeline--20 .save-timeline__item {
	width: 20%;
}

.see-instruments-wrap {
	padding: 75px 0;
}

.see-instruments {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom: 40px;
	position: relative;
}

.see-instruments::before {
	content: '';
	position: absolute;
	right: -70px;
	bottom: 0;
	width: 70%;
	height: 75%;
	border-right: 2px solid #29CB7E;
	border-bottom: 2px solid #29CB7E;
}

.see-instruments:hover .see-instruments-images {
	transform: rotate(0);
}

.see-instruments:hover .see-instruments-images--1 {
	left: 0;
	top: 0;
}

.see-instruments:hover .see-instruments-images--2 {
	left: 53%;
	top: 0;
}

.see-instruments:hover .see-instruments-images--3 {
	left: 75%;
	top: 0;
}

.see-instruments:hover .see-instruments-images--4 {
	left: 34%;
	top: 66%;
}

.see-instruments:hover .see-instruments-images--5 {
	left: 48%;
	top: 40%;
}

.see-instruments:hover .see-instruments-images--6 {
	left: 83%;
	top: 39%;
}

.see-instruments:hover .see-instruments-images--7 {
	left: 82%;
	top: 73%;
}

.see-instruments__images {
	width: 728px;
	height: 389px;
	position: relative;
}

.see-instruments__info {
	margin-left: 50px;
}

.see-instruments__title {
	font-family: Arimo;
	font-size: 28px;
	line-height: 1.1;
	color: #10100F;
	margin-bottom: 40px;
}

.see-instruments__text {
	font-family: Montserrat;
	font-weight: 500;
	font-size: 18px;
	line-height: 1.3;
	color: #10100F;
	margin-bottom: 50px;
	max-width: 640px;
}

.see-instruments .see-instruments-images {
	position: absolute;
	transition: all 300ms ease-in-out;
}

.see-instruments .see-instruments-images--1 {
	left: 10%;
	top: 13%;
	width: 50%;
	transform: rotate(-32deg);
}

.see-instruments .see-instruments-images--2 {
	left: 56%;
	top: 0;
	width: 18%;
	transform: rotate(20.74deg);
}

.see-instruments .see-instruments-images--3 {
	left: 64%;
	top: 21%;
	width: 24%;
	transform: rotate(50deg);
}

.see-instruments .see-instruments-images--4 {
	left: 39%;
	top: 65%;
	width: 13%;
	transform: rotate(16.15deg);
}

.see-instruments .see-instruments-images--5 {
	left: 49%;
	top: 40%;
	width: 32%;
	transform: rotate(24deg);
}

.see-instruments .see-instruments-images--6 {
	left: 75%;
	top: 44%;
	width: 17%;
	transform: rotate(-12deg);
}

.see-instruments .see-instruments-images--7 {
	left: 49%;
	top: 74%;
	width: 12%;
	transform: rotate(-29.43deg);
}

.try-instruments-wrap {
	padding: 75px 0 150px;
}

.try-instruments__title {
	font-family: Arimo;
	font-size: 66px;
	line-height: 1.1;
	text-align: center;
	color: #10100F;
	margin-bottom: 70px;
}

.try-instruments__block {
	min-width: 0;
	min-height: 0;
	display: flex;
	margin: 0 -10px;
}

.try-instruments__item {
	width: 33.3333%;
	padding: 0 10px;
	box-sizing: border-box;
	display: flex;
}

.try-instruments__ico {
	min-width: 80px;
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	border: 1px solid #FFC90B;
	border-radius: 100%;
	margin-right: 20px;
}

.try-instruments__caption {
	font-family: Montserrat;
	font-weight: 500;
	font-size: 20px;
	line-height: 1.5;
	color: #10100F;
	margin: 25px 0 20px;
}

.try-instruments__text {
	font-family: Montserrat;
	font-size: 14px;
	line-height: 1.5;
	color: #10100F;
	margin-bottom: 10px;
}

.try-instruments__link {
	margin-bottom: 40px;
}

.try-instruments__link a {
	display: inline-block;
	font-family: Montserrat;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.5;
	color: #10100F;
	border-bottom: 1px solid #29CB7E;
	text-decoration: none;
}

.try-instruments__link a:hover {
	border-color: transparent;
}

.try-instruments__more {
	text-align: center;
	margin-top: 60px;
}

.try-instruments__more a {
	font-family: Montserrat;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	color: #10100F;
	border-bottom: 1px solid #29CB7E;
	text-decoration: none;
}

.try-instruments__more a:hover {
	border-color: transparent;
}

.try-instruments-bullets {
	display: flex;
	margin: 0 -5px;
}

.try-instruments-bullets__item {
	width: 50%;
	padding: 0 4px;
	box-sizing: border-box;
}

.try-instruments-bullets__ico {
	text-align: center;
	margin-bottom: 5px;
}

.try-instruments-bullets__title {
	font-family: Montserrat;
	font-size: 14px;
	line-height: 1.5;
	text-align: center;
	color: #10100F;
}

.komondor-left {
	position: relative;
}

.komondor-left::before {
	content: '';
	position: absolute;
	left: 30px;
	top: 0;
	width: 130px;
	height: 100%;
	background: url('../images/komondor-left.png') no-repeat center;
	background-size: contain;
}

.komondor-right {
	position: relative;
	overflow: unset;
}

.komondor-right::before {
	content: '';
	position: absolute;
	right: 30px;
	bottom: 30px;
	width: 187px;
	height: 100%;
	background: url('../images/komondor-right.png') no-repeat center;
	background-size: contain;
}

.cat-inst-content-wrap {
	padding: 75px 0;
}

.cat-inst-content-wrap.last {
	padding: 200px 0;
}

.cat-inst-content {
	position: relative;
}

.cat-inst-content__title {
	font-family: Arimo;
	font-size: 28px;
	line-height: 32px;
	color: #10100F;
	margin-bottom: 40px;
	max-width: 572px;
}

.cat-inst-content__text {
	font-family: Montserrat;
	font-size: 18px;
	line-height: 1.5;
	color: #10100F;
	max-width: 572px;
	margin-bottom: 100px;
}

.cat-inst-content__image {
	position: absolute;
	right: 0;
	top: 0;
}

.cat-inst-content__image.center {
	top: 50%;
	transform: translateY(-50%);
}

.cat-inst-content__image img {
	max-width: 100%;
}

.cat-inst-content__block {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
}

.cat-inst-content__block .try-instruments__text {
	max-width: 267px;
}

.cat-inst-content__block--special .try-instruments__item:first-child {
	width: 100%;
}

.tariff-intro-wrap {
	padding: 150px 0 75px;
}

.tariff-intro__title {
	font-family: Arimo;
	font-weight: bold;
	font-size: 60px;
	line-height: 1.3;
	text-align: center;
	color: #10100F;
	margin-bottom: 40px;
}

.tariff-intro__text {
	font-family: Montserrat;
	font-weight: 500;
	font-size: 24px;
	line-height: 1.5;
	text-align: center;
	color: #10100F;
	margin-bottom: 50px;
}

.tariff-radio {
	display: flex;
	justify-content: center;
}

.tariff-radio input {
	display: none;
}

input:checked + .tariff-radio__button {
	background-color: #29CB7E;
	color: #fff;
}

.tariff-radio__label {
	cursor: pointer;
	margin: 0 25px;
	position: relative;
}

.tariff-radio__button {
	background-color: transparent;
	border: 1px solid #29CB7E;
	color: #29CB7E;
	transform: scale(1) !important;
}

.tariff-radio__percent {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	font-family: Montserrat;
	font-weight: 600;
	font-size: 20px;
	line-height: 1.3;
	text-align: center;
	color: #10100F;
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #FFFFFF;
	border: 1px solid #29CB7E;
	box-sizing: border-box;
	border-radius: 100%;
}

.tariff__radio {
	margin-bottom: 70px;
}

.tariff__block {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.tariff__item {
	position: relative;
	width: 370px;
	height: 690px;
	background: #FFFFFF;
	box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
	padding: 30px 0;
}

.tariff__item--special {
	padding: 55px 0;
	width: 400px;
	height: 740px;
	border: 1px solid #29CB7E;
}

.tariff__item:first-child .tariff__list li:last-child::before {
	display: none;
}

.tariff__item--special .tariff__title {
	margin-bottom: 25px;
}

.tariff__item--special .tariff__price {
	margin-bottom: 30px;
}

.tariff__badge {
	position: absolute;
	top: 30px;
	right: -50px;
	background: #29CB7E;
	font-family: Montserrat;
	font-weight: 600;
	font-size: 14px;
	line-height: 30px;
	width: 200px;
	text-align: center;
	color: #FFFFFF;
	transform: rotate(45deg);
}

.tariff__title {
	font-family: Montserrat;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	text-align: center;
	color: #29CB7E;
	margin-bottom: 40px;
}

.tariff__price {
	font-family: Montserrat;
	font-weight: 600;
	font-size: 30px;
	line-height: 44px;
	text-align: center;
	color: #000000;
	margin-bottom: 40px;
}

.tariff__price--small {
	font-family: Montserrat;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #000000;
}

.tariff__caption {
	font-family: Montserrat;
	font-size: 16px;
	line-height: 1.25;
	text-align: center;
	color: #000000;
	max-width: 292px;
	margin: 0 auto 15px;
}

.tariff__list {
	padding: 0 30px;
	margin-bottom: 20px;
}

.tariff__list li {
	padding-left: 20px;
	position: relative;
	font-family: Montserrat;
	font-size: 14px;
	line-height: 1.5;
	color: #000000;
	margin-bottom: 10px;
}

.tariff__list li::before {
	content: '';
	width: 10px;
	height: 10px;
	background: url('../images/menu-arrow.svg') no-repeat center;
	background-size: 80%;
	position: absolute;
	left: 0;
	top: 6px;
	line-height: 14px;
	opacity: 1;
}

.tariff__button {
	width: auto;
	min-height: 44px;
	height: 44px;
	padding: 0 40px;
	margin-bottom: 20px;
}

.tariff__more {
	display: block;
	margin: 0 auto;
	font-family: Montserrat;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.5;
	color: #10100F;
	border-bottom: 1px solid #29CB7E;
	text-decoration: none;
}

.tariff__more:hover {
	border-color: transparent;
}

.compare-wrap {
	padding: 75px 0;
}

.compare__title {
	font-family: Arimo;
	font-weight: normal;
	font-size: 60px;
	line-height: 1.25;
	text-align: center;
	color: #10100F;
	margin-bottom: 70px;
}

.compare-block {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	background: #FFFFFF;
	border: 1px solid #F6F6F6;
	box-sizing: border-box;
	box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
	border-radius: 17px 17px 0px 0px;
}

.compare-block > div {
	border: 1px solid #F6F6F6;
	padding: 25px 30px;
}

.compare-block__head {
	text-align: center;
}

.compare-block__title {
	font-family: Montserrat;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: #29CB7E;
	margin-bottom: 15px;
}

.compare-block__price {
	font-family: Montserrat;
	font-weight: 600;
	font-size: 26px;
	line-height: 1.3;
	color: #000000;
	margin-bottom: 10px;
}

.compare-block__text {
	font-family: Montserrat;
	font-size: 14px;
	line-height: 1.4;
	color: #000000;
	margin-bottom: 15px;
}

.compare-block__link {
	display: inline-block;
	background: #29CB7E;
	border-radius: 30px;
	padding: 10px 40px;
	font-family: Montserrat;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #FFFFFF;
	box-sizing: border-box;
	text-decoration: none;
}

.compare-block__cell {
	font-family: Montserrat;
	font-weight: normal;
	font-size: 16px;
	line-height: 1.4;
	letter-spacing: 0.2px;
	color: #10100F;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.compare-block__bg {
	background-color: #F6F6F6;
}

.compare-block__left {
	font-size: 14px;
	justify-content: flex-start;
	text-align: left;
}

.compare-block__color {
	color: #F24242;
}

.faq-wrap {
	padding: 0 0 75px;
}

.faq-list__item {
	background: #FFFFFF;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	margin-bottom: 15px;
}

.faq-list__question {
	font-family: Montserrat;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	color: #10100F;
	padding: 20px 40px;
	position: relative;
	cursor: pointer;
}

.faq-list__question:hover {
	color: #29CB7E;
}

.faq-list__question::after {
	content: '';
	position: absolute;
	top: 28px;
	right: 40px;
	width: 10px;
	height: 10px;
	background: url('../images/faq-arrow.svg') no-repeat center;
	background-size: contain;
	transform: rotate(180deg);
}

.faq-list__question.active {
	color: #29CB7E;
}

.faq-list__question.active::after {
	transform: rotate(0);
}

.faq-list__answer {
	display: none;
	font-family: Montserrat;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	color: #10100F;
	padding: 20px 40px;
}

.not-found-wrap {
	padding-top: 180px;
}

.not-found-wrap::before {
	content: '';
	position: absolute;
	left: 82px;
	top: 0;
	width: 1px;
	height: 60%;
	background-color: #FFC90B;
}

.not-found {
	display: flex;
}

.not-found__item {
	width: 50%;
}

.not-found__title {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 1.5;
	color: #10100F;
	margin-bottom: 25px;
}

.not-found__title img {
	vertical-align: middle;
}

.not-found__links {
	margin: 50px 0;
}

.not-found__links a {
	display: inline-block;
	padding: 16px 50px;
	border: 2px solid #29CB7E;
	box-sizing: border-box;
	border-radius: 30px;
	font-family: Montserrat;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	color: #29CB7E;
	margin-right: 15px;
	text-decoration: none;
}

.not-found__links a:hover {
	background: #29CB7E;
	color: #fff;
}

.not-found__number {
	font-family: Arimo;
	font-size: 360px;
	line-height: 1;
	color: #29CB7E;
}

.tariff-partners-wrap {
	padding: 75px 0;
}

.tariff-partners__block {
	margin: 0 auto;
	max-width: 1650px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.tariff-partners__block img {
	height: auto;
	margin: 20px;
}

.try-instruments {
	position: relative;
}

.try-instruments__gallery {
	position: relative;
	padding: 0 40px;
}

.try-instruments__gallery .try-instruments__item {
	display: flex !important;
	max-width: 420px;
	margin: 0 auto;
}

.try-instruments__gallery .try-instruments-bullets__ico img {
	margin: 0 auto;
}

.try-instruments__gallery--arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	padding: 15px;
}

.try-instruments__gallery--arrow--prev {
	left: -40px;
	padding-left: 0;
}

.try-instruments__gallery--arrow--next {
	right: -40px;
	padding-right: 0;
}

.about-intro-wrap {
	padding: 150px 0 50px;
	position: relative;
}

.about-intro-wrap::before {
	content: '';
	position: absolute;
	left: 82px;
	top: 0;
	width: 1px;
	height: 100%;
	background-color: #FFC90B;
}

.about-intro {
	position: relative;
	padding-bottom: 70px;
}

.about-intro::after {
	content: '';
	position: absolute;
	bottom: 0;
	right: 0;
	width: 80%;
	height: 60%;
	border-bottom: 1px solid #29CB7E;
	border-right: 1px solid #29CB7E;
}

.about-intro__title {
	font-family: Arimo;
	font-weight: bold;
	font-size: 60px;
	line-height: 1.3;
	text-align: center;
	color: #10100F;
	margin-bottom: 70px;
}

.about-intro__block {
	display: flex;
}

.about-intro__content {
	font-family: Montserrat;
	font-size: 20px;
	line-height: 1.5;
	color: #10100F;
	width: 100%;
	max-width: 874px;
	margin-right: 85px;
}

.about-intro__content p {
	margin-bottom: 25px;
}

.about-intro__content p:last-child {
	margin-bottom: 0;
}

.about-intro__logos {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.about-intro__logos img:first-child {
	margin-bottom: 117px;
}

.about-team-wrap {
	padding: 75px 0;
}

.about-team__title {
	margin-bottom: 40px;
}

.about-team__caption {
	font-family: Montserrat;
	font-weight: 500;
	font-size: 24px;
	line-height: 1.5;
	text-align: center;
	color: #000000;
	max-width: 650px;
	margin: 0 auto 70px;
}

.about-team__row {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.about-team__item {
	max-width: 255px;
	margin: 0 20px 50px;
	text-align: center;
}

.about-team__photo {
	width: 140px;
	height: 140px;
	border: 1px solid #29CB7E;
	border-radius: 100%;
	overflow: hidden;
	margin: 0 auto;
}

.about-team__name {
	font-family: Montserrat;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: #000000;
	margin: 25px 0 10px;
}

.about-team__text {
	font-family: Montserrat;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #000000;
}

.join-wrap.komondor-right {
	padding: 75px 0;
}

.about-numbers-wrap {
	padding: 75px 0 25px;
}

.about-numbers__block {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.about-numbers__item {
	display: flex;
	align-items: center;
	margin: 0 10px 50px;
}

.about-numbers__count {
	font-family: Arimo;
	font-size: 48px;
	line-height: 55px;
	text-align: center;
	color: #29CB7E;
	margin-right: 10px;
}

.about-numbers__bold {
	font-family: Montserrat;
	font-weight: 500;
	font-size: 20px;
	line-height: 1.1;
	color: #10100F;
}

.about-numbers__text {
	font-family: Montserrat;
	font-size: 16px;
	line-height: 1.4;
	color: #10100F;
}

.partners-block__link {
	margin: 50px auto 100px;
	width: 100%;
	max-width: 400px;
}

.page-blog {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
}

.page-blog__item {
	width: 33.3333%;
	padding: 0 15px;
	margin-bottom: 40px;
	box-sizing: border-box;
	text-decoration: none;
}

.page-blog__thumbnail {
	width: 100%;
	height: 260px;
	overflow: hidden;
	position: relative;
	margin-bottom: 15px;
}

.page-blog__thumbnail img {
	position: absolute;
	left: 0;
	top: 50%;
	height: auto;
	width: 100%;
	transform: translateY(-50%);
}

.page-blog__title {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 1.5;
	color: #000000;
	margin-bottom: 15px;
}

.page-blog__date {
	font-family: Montserrat;
	font-size: 16px;
	line-height: 20px;
	text-align: left;
	color: #000000;
}

.wp-pagenavi {
	margin: 4vw 0;
	width: 100%;
	display: flex;
	justify-content: center;
}

.content .blocks-gallery-grid .blocks-gallery-image figure,
.content .blocks-gallery-grid .blocks-gallery-item figure,
.content .wp-block-gallery .blocks-gallery-image figure,
.content .wp-block-gallery .blocks-gallery-item figure {
	align-items: normal;
}

.content strong {
	font-weight: 700;
}

.content em {
	font-style: italic;
}

.content p {
	font-size: 18px;
	line-height: 1.67;
	color: #3f3d3f;
	margin-bottom: 25px;
}

.content img {
	display: block;
	margin-bottom: 25px;
	max-width: 100%;
}

.content a {
	color: inherit;
}

.content ul,
.content ol {
	list-style: none;
	margin-bottom: 25px;
}

.content ul li,
.content ol li {
	position: relative;
	padding-left: 25px;
	font-size: 18px;
	line-height: 1.67;
	color: #3f3d3f;
	margin-bottom: 20px;
}

.content ul li:last-child,
.content ol li:last-child {
	margin-bottom: 0;
}

.content ul li::before,
.content ol li::before {
	content: '';
	position: absolute;
	left: 0;
	top: 13px;
	border-radius: 100%;
	width: 7px;
	height: 7px;
	background-color: #FFC90B;
}

.content .wp-block-gallery li {
	padding-left: 0;
}

.content .wp-block-gallery li::before {
	display: none;
}

.content .wp-blockquote > p {
	font-size: 130%;
}

.content h2 {
	font-size: 1.97em;
	margin: 0.75em 0;
}

.content h3 {
	font-size: 1.72em;
	margin: 0.83em 0;
}

.content h4 {
	font-size: 1.5em;
	margin: 0.83em 0;
}

.content h5 {
	font-size: 1.31em;
	margin: 1.5em 0;
}

.content h6 {
	font-size: 1.16em;
	margin: 1.67em 0;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
	font-weight: bolder;
}

@media all and (max-width: 1600px) {

.komondor-left::before {
	left: 10px;
	width: 100px;
}

.komondor-right::before {
	right: 10px;
	width: 140px;
}

.about-intro-wrap {
	padding: 120px 0 50px;
}

.about-intro-wrap::before {
	left: 30px;
}

.partners-wrap__line {
	left: 10vw;
}

.partners-wrap__border-right {
	right: 10vw;
}

.partners-wrap__border-bottom {
	right: 10vw;
}

.about-intro__title {
	margin-bottom: 50px;
}

.about-intro__content {
	font-size: 18px;
}

.about-intro__content p {
	margin-bottom: 20px;
}

.try-instruments-wrap {
	padding: 75px 0;
}

.not-found-wrap {
	padding-top: 130px;
}

.not-found-wrap::before {
	left: 15px;
	height: 50%;
}

.not-found {
	align-items: center;
}

.not-found__number {
	font-size: 300px;
}

.try-instruments__gallery--arrow--prev {
	left: -20px;
}

.try-instruments__gallery--arrow--next {
	right: -20px;
}

}

@media all and (max-width: 1500px) {

.main-title {
	font-size: 64px;
	line-height: 72px;
}

.intro-wrap {
	padding: 150px 0 120px;
}

.intro-wrap__line {
	left: 1vw;
}

.intro-wrap__komondor {
	width: 600px;
}

.intro__image {
	right: -50px;
}

.intro__title {
	font-size: 72px;
	line-height: 1;
	max-width: 675px;
	margin-bottom: 16px;
}

.intro-laptop__img {
	width: 550px;
}

.helps-wrap {
	margin: 80px 0 160px;
}

.join-wrap {
	margin: 120px 0;
}

.join__form {
	margin-left: 40px;
}

.form-theme {
	width: 542px;
	height: 491px;
	padding: 50px;
}

.join__info {
	margin-left: 70px;
}

.say-wrap {
	margin: 120px 0;
}

.say {
	max-width: 1100px;
	margin: 0 auto;
}

.say-slider__content {
	margin: 0 auto 20px;
}

.say-slider-previous {
	left: -60px;
}

.say-slider-next {
	right: -60px;
}

.partners-wrap {
	margin: 120px 0;
}

.partners-wrap__border-right {
	top: calc(50% + 35px);
}

.partners-wrap__border-bottom {
	bottom: -35px;
}

.partners-wrap .container {
	max-width: 1100px;
}

.partners-block__item img {
	max-width: 125px;
}

.save-wrap {
	margin: 120px 0;
}

.save-wrap .container {
	max-width: 1160px;
}

.save-block__image {
	margin-left: 45px;
}

.save-timeline {
	max-width: 1260px;
}

.footer-wrap .container {
	max-width: 1260px;
}

.footer__block {
	margin-bottom: 30px;
}

.footer__input input:not([type=submit]) {
	width: 280px;
}

}

@media all and (max-width: 1300px) {

.container {
	padding: 0 60px;
}

.main-title {
	font-size: 50px;
	line-height: 58px;
	margin-bottom: 45px;
}

.intro-wrap {
	padding: 120px 0 100px;
}

.intro-wrap__line {
	left: 2vw;
}

.intro-wrap__komondor {
	width: 50vw;
}

.intro__image {
	right: -75px;
	width: 50%;
}

.intro__title {
	font-size: 6vw;
	line-height: 1;
	max-width: 52vw;
}

.intro__sub {
	font-size: 18px;
	max-width: 350px;
}

.intro-laptop__block {
	right: 20%;
}

.intro-laptop__border {
	right: 20%;
}

.helps-wrap {
	margin: 60px 0 120px;
}

.helps-wrap .container {
	padding: 0 20px;
}

.helps__tabs {
	padding-left: 35px;
}

.instruments {
	height: 435px;
}

.instruments__items {
	padding: 12px 0 12px 40px;
}

.instruments__title {
	font-size: 20px;
	line-height: 26px;
	margin-bottom: 15px;
}

.instruments__text {
	font-size: 16px;
	line-height: 20px;
}

.tabs__links .tabs__item {
	width: 165px !important;
	height: 34px;
	margin: 0 10px;
	font-size: 18px;
	line-height: 24px;
}

.tabs__links .tabs__item::before {
	height: 2px;
}

.tabs__links {
	margin-bottom: 20px;
}

.join__form {
	margin-left: 0;
}

.form-theme__checkbox .checkbox-text {
	font-size: 14px;
	line-height: 18px;
}

.form-theme__checkbox .checkbox-text::before {
	width: 25px;
	height: 25px;
	top: -3px;
}

.form-theme__checkbox .checkbox-text::after {
	left: 12px;
	top: -9px;
	width: 10px;
	height: 24px;
}

.form-theme a {
	font-size: 14px;
	line-height: 18px;
}

.form-theme__caption {
	font-size: 14px;
	line-height: 18px;
}

.form-theme {
	width: 440px;
	height: 440px;
	padding: 30px;
}

.form-theme__title {
	font-size: 26px;
	line-height: 30px;
	margin-bottom: 26px;
}

.join__title {
	font-size: 24px;
	line-height: 32px;
	margin-bottom: 30px;
}

.join__sub-title {
	font-size: 18px;
	line-height: 22px;
}

.say-slider__content {
	width: 550px;
	padding: 50px 50px 30px 80px;
}

.say-slider__name {
	font-size: 20px;
	line-height: 24px;
}

.say-slider__post {
	font-size: 16px;
	line-height: 20px;
}

.save-block__content p {
	font-size: 16px;
	margin-bottom: 20px;
}

.save-block__content ul li {
	font-size: 20px;
}

.save-block__image {
	margin-left: 20px;
}

.save-block__image img {
	width: 100%;
}

.save-block__content {
	flex: 0 0 430px;
}

.save-timeline {
	margin: 50px auto 0;
}

.save-timeline__title {
	font-size: 18px;
	line-height: 22px;
}

.access__block {
	margin-bottom: 80px;
}

.access__item {
	font-size: 20px;
	line-height: 25px;
}

.footer-wrap .container {
	padding: 0 30px;
}

.footer-wrap {
	padding: 30px 0;
}

.footer__block {
	flex-wrap: wrap;
}

.footer__socials {
	margin-top: 40px;
	flex: 1 1 auto;
	display: flex;
	justify-content: space-between;
}

.footer__bot {
	margin-bottom: 40px;
}

.about-intro {
	padding-bottom: 0;
}

.about-intro::after {
	display: none;
}

.about-intro__title {
	font-size: 50px;
}

.about-team__title {
	margin-bottom: 30px;
}

.about-team__caption {
	font-size: 20px;
	max-width: 630px;
	margin: 0 auto 50px;
}

.about-team__row {
	flex-wrap: wrap;
}

.not-found-wrap {
	padding-top: 150px;
}

.not-found-wrap::before {
	left: 30px;
}

.not-found__title {
	font-size: 18px;
	margin-bottom: 20px;
}

.not-found__links {
	margin: 20px 0;
}

.not-found__number {
	font-size: 240px;
}

.tariff-intro-wrap {
	padding: 120px 0 75px;
}

.tariff-intro__title {
	font-size: 50px;
}

.tariff-intro__text {
	font-size: 20px;
}

.tariff__title {
	margin-bottom: 25px;
}

.tariff__price {
	font-size: 24px;
	line-height: 1.3;
	margin-bottom: 25px;
}

.tariff__item--special {
	z-index: 10;
}

.tariff__list {
	padding: 0 20px;
}

.tariff__item--special .tariff__title {
	margin-bottom: 15px;
}

.tariff__item--special .tariff__price {
	margin-bottom: 15px;
}

.compare-block > div {
	padding: 15px;
}

.compare-block__price {
	font-size: 22px;
}

.compare-block__price small {
	font-size: 16px;
}

.compare-block__link {
	padding: 10px 20px;
	font-size: 14px;
	line-height: 1.3;
}

.compare-block__cell {
	font-size: 14px;
}

.partners-wrap__line {
	left: 6vw;
}

.partners-wrap__border-right,
.partners-wrap__border-bottom {
	right: 6vw;
}

.intro-instruments-wrap {
	padding: 120px 0 75px;
}

.intro-instruments-wrap::before {
	left: 30px;
}

.intro-instruments__title {
	font-size: 50px;
}

.intro-instruments__text {
	font-size: 20px;
}

.intro-instruments__list ul {
	margin: 0 -30px;
}

.intro-instruments__list ul li {
	margin: 0 30px 50px;
}

.intro-instruments-image {
	margin-bottom: 50px;
}

.intro-instruments-image.animate .intro-instruments-image__border {
	width: 900px;
	left: -60px;
	bottom: -30px;
	transform: rotate(30deg) translateX(-140px);
}

.intro-instruments-image.animate .intro-instruments-image__block {
	width: 900px;
	left: -60px;
	bottom: -30px;
}

.content-instruments__block {
	flex-direction: column;
	margin: 0 0 40px;
}

.content-instruments__info {
	order: 1;
	margin-bottom: 20px;
}

.content-instruments__image {
	order: 2;
}

.services-instruments__title,
.advantages-instruments__title,
.how-instruments__title,
.try-instruments__title {
	font-size: 46px;
}

.see-instruments::before {
	right: -30px;
}

.see-instruments__images {
	height: 285px;
}

.try-instruments__block {
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.try-instruments__item {
	width: 50%;
	margin-bottom: 40px;
}

.try-instruments__text {
	max-width: 260px;
}

.try-instruments__more {
	margin-top: 20px;
}

.try-instruments-bullets__item {
	width: 45%;
}

.cat-inst-content__title {
	max-width: 750px;
	text-align: center;
	margin: 0 auto 30px;
}

.cat-inst-content__text {
	max-width: 750px;
	margin: 0 auto 30px;
	text-align: center;
}

.cat-inst-content__image {
	position: static;
	margin-bottom: 50px;
	text-align: center;
}

.cat-inst-content__image.center {
	transform: translateY(0);
}

.cat-inst-content__block--special .try-instruments__item:first-child {
	width: 50%;
}

.cat-inst-content-wrap {
	padding: 75px 0;
}

.cat-inst-content-wrap.last {
	padding-bottom: 100px;
}

}

@media all and (max-width: 1280px) {

.komondor-left::before {
	display: none;
}

.komondor-right::before {
	display: none;
}

}

@media all and (max-width: 1024px) {

.page-blog__item {
	width: 50%;
}

}

@media all and (max-width: 1000px) {

.main-title {
	font-size: 40px;
	line-height: 48px;
	margin-bottom: 30px;
}

.header__block {
	padding-right: 70px;
}

.lang-dropdown {
	margin-left: 40px;
}

.intro__image {
	top: 40%;
}

.intro-laptop__block {
	display: none;
}

.intro-laptop__border {
	display: none;
}

.helps__block {
	display: block;
}

.helps__instruments {
	width: 100%;
}

.helps__tabs {
	padding-left: 0;
	margin: 0 auto;
	width: 100%;
	max-width: 600px;
}

.instruments {
	height: initial;
	overflow: initial;
	margin-bottom: 50px;
}

.join {
	flex-direction: column;
	align-items: center;
}

.join__form {
	order: 2;
	flex: none;
}

.join__info {
	flex: none;
}

.join__info {
	margin-top: 0;
	margin-bottom: 70px;
}

.say-slider-previous {
	left: -30px;
}

.say-slider-next {
	right: -30px;
}

.partners-block__item img {
	max-width: 90px;
	max-height: 90px;
}

.save-timeline__title {
	font-size: 16px;
	line-height: 20px;
}

.access__item {
	margin: 0 15px;
}

.footer-wrap .container {
	padding: 0 15px;
}

.footer__top {
	margin-bottom: 30px;
}

.footer__block {
	flex-direction: column;
	align-items: center;
}

.footer__navigation {
	order: 1;
}

.footer__socials {
	order: 3;
	margin-top: 30px;
}

.footer__socials a {
	width: 170px;
	height: 45px;
	font-size: 14px;
	margin: 0 5px;
}

.footer__subscribes {
	order: 0;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	margin-bottom: 30px;
}

.footer__buttons {
	margin-bottom: 7px;
}

.footer__label {
	margin-bottom: 5px;
}

.footer__input input:not([type=submit]) {
	width: 260px;
	height: 45px;
}

.footer__input input[type=submit] {
	height: 45px;
}

.about-intro-wrap {
	padding: 100px 0 50px;
}

.about-intro__title {
	font-size: 40px;
	margin-bottom: 40px;
}

.about-intro__block {
	flex-direction: column;
}

.about-intro__content {
	font-size: 16px;
	margin-bottom: 30px;
}

.about-intro__logos {
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.about-intro__logos img {
	margin: 0 20px !important;
}

.about-team-wrap {
	padding: 50px 0 0;
}

.about-team__title {
	margin-bottom: 30px;
}

.about-team__caption {
	font-size: 18px;
	max-width: 570px;
	margin: 0 auto 40px;
}

.about-team__item {
	min-width: 230px;
	width: 230px;
}

.about-numbers-wrap {
	padding: 50px 0;
}

.about-numbers__block {
	margin: 0 -20px;
}

.about-numbers__item {
	width: 50%;
	margin: 0 0 50px;
	padding: 0 20px;
	box-sizing: border-box;
}

.about-numbers__bold {
	font-size: 18px;
}

.try-instruments__gallery {
	display: block;
}

.not-found-wrap {
	padding-top: 120px;
}

.not-found-wrap::before {
	left: 10px;
}

.not-found {
	flex-wrap: wrap;
}

.not-found__item {
	width: 100%;
	text-align: center;
}

.not-found__item:last-child {
	order: -1;
}

.not-found__number {
	font-size: 200px;
}

.tariff-intro-wrap {
	padding: 120px 0 50px;
}

.tariff-intro__title {
	font-size: 40px;
}

.tariff-intro__text {
	font-size: 18px;
}

.tariff-radio {
	margin: 0 -8px;
}

.tariff-radio__label {
	margin: 0 8px;
}

.tariff-radio__button {
	width: 200px;
	height: 44px;
	font-size: 16px;
	font-weight: 500;
}

.tariff-radio__percent {
	font-size: 14px;
	width: 45px;
	height: 45px;
	transform: translateY(0);
	top: -25px;
	right: -15px;
}

.tariff__block {
	flex-direction: column;
}

.tariff__item {
	height: auto;
	margin-bottom: 25px;
}

.tariff__item--special {
	height: auto;
}

.tariff__caption {
	margin: 0 auto 25px;
}

.compare-wrap {
	padding: 50px 0;
}

.compare-block-wrap {
	overflow-x: auto;
}

.compare-block {
	min-width: 800px;
	box-shadow: none;
	border-width: 2px;
}

.faq-wrap {
	padding: 0 0 50px;
}

.faq-list__question {
	padding: 20px;
}

.faq-list__answer {
	padding: 20px;
}

.intro-instruments-wrap {
	padding: 120px 0 50px;
}

.intro-instruments-wrap::before {
	left: 10px;
}

.intro-instruments__title {
	font-size: 40px;
}

.intro-instruments__text {
	font-size: 18px;
	margin: 0 auto 30px;
}

.intro-instruments__list::after {
	display: none;
}

.intro-instruments-image {
	margin-bottom: 0;
}

.intro-instruments-image__border {
	display: none;
}

.intro-instruments-image__block {
	display: none;
}

.intro-instruments-image img {
	width: 100%;
}

.services-instruments__title,
.advantages-instruments__title,
.how-instruments__title,
.try-instruments__title {
	font-size: 34px;
	margin-bottom: 40px;
}

.content-instruments-wrap,
.advantages-instruments-wrap,
.how-instruments-wrap,
.see-instruments-wrap,
.services-instruments-wrap {
	padding: 50px 0;
}

.advantages-instruments__block {
	flex-direction: column;
	margin: 0;
}

.advantages-instruments__item {
	width: 100%;
	padding: 0;
	margin-bottom: 40px;
}

.advantages-instruments__icon {
	margin-bottom: 20px;
}

.advantages-instruments__caption {
	margin-bottom: 15px;
}

.advantages-instruments__text {
	max-width: 440px;
	margin: 0 auto;
}

.how-instruments .save-timeline {
	flex-direction: column;
	text-align: left;
	padding: 0 4vw;
}

.how-instruments .save-timeline__text {
	font-size: 18px;
	text-align: left;
}

.how-instruments .save-timeline--20 .save-timeline__item {
	width: 100%;
}

.how-instruments .save-timeline__item {
	width: 100%;
	margin-bottom: 25px;
}

.how-instruments .save-timeline__item::before {
	top: 70%;
	height: 100%;
	width: 4px;
	right: 12px;
}

.how-instruments .save-timeline__item::after {
	top: 70%;
	height: 100%;
	width: 4px;
	right: 12px;
}

.how-instruments .save-timeline__dot {
	position: absolute;
	top: 0;
	right: 0;
}

.see-instruments {
	flex-direction: column;
	padding-bottom: 0;
}

.see-instruments::before {
	display: none;
}

.see-instruments__images {
	order: 2;
	height: 390px;
}

.see-instruments__info {
	order: 1;
	margin: 0 0 50px;
	text-align: center;
}

.see-instruments__info .theme-button {
	margin: 0 auto;
}

.see-instruments .see-instruments-images {
	transform: rotate(0);
}

.see-instruments .see-instruments-images--1 {
	left: 0;
	top: 0;
}

.see-instruments .see-instruments-images--2 {
	left: 53%;
	top: 0;
}

.see-instruments .see-instruments-images--3 {
	left: 75%;
	top: 0;
}

.see-instruments .see-instruments-images--4 {
	left: 34%;
	top: 66%;
}

.see-instruments .see-instruments-images--5 {
	left: 48%;
	top: 40%;
}

.see-instruments .see-instruments-images--6 {
	left: 83%;
	top: 39%;
}

.see-instruments .see-instruments-images--7 {
	left: 82%;
	top: 73%;
}

.try-instruments-wrap {
	padding: 50px 0 75px;
}

.try-instruments__block {
	flex-direction: column;
	margin: 0;
}

.try-instruments__item {
	width: 100%;
	padding: 0;
	max-width: 460px;
}

.try-instruments__text {
	max-width: 100%;
}

.cat-inst-content__title {
	font-size: 24px;
	max-width: 750px;
	text-align: center;
	margin: 0 auto 30px;
}

.cat-inst-content__text {
	font-size: 16px;
	max-width: 750px;
	margin: 0 auto 30px;
	text-align: center;
}

.cat-inst-content__image {
	position: static;
	margin-bottom: 50px;
	text-align: center;
}

.cat-inst-content__image.center {
	transform: translateY(0);
}

.cat-inst-content__block .try-instruments__item {
	width: 50%;
}

.cat-inst-content__block--special .try-instruments__item:first-child {
	width: 50%;
}

.cat-inst-content-wrap {
	padding: 50px 0;
}

.cat-inst-content-wrap.last {
	padding-bottom: 75px;
}

.header-nav-close {
	display: block;
}

.hamburger {
	display: flex;
}

.header__block {
	padding: 0 10px;
	padding-right: 70px;
}

.header__nav {
	position: fixed;
	z-index: 100;
	top: 0;
	right: 0;
	margin: 0 !important;
	height: 100%;
	background-color: #ffffff;
	width: 320px;
	transition: all 0.2s linear;
	will-change: transform;
	transform: translateX(100%);
}

.header__nav.open {
	transform: translateX(0);
}

.header__nav .navigation {
	display: block;
	padding-top: 40px;
}

.header__nav .navigation > li {
	margin-right: 0;
	border-bottom: 1px solid #ccc;
}

.header__nav .navigation > li > a {
	font-size: 20px;
	line-height: 40px;
	padding: 0 15px;
	font-weight: 500;
}

.header__nav .menu-item-has-children > a::after {
	right: 10px;
}

.header__nav .sub-menu {
	position: static;
	border: none;
	border-radius: 0;
	padding: 10px 25px;
}

.header__nav .sub-menu::before,
.header__nav .sub-menu::after {
	display: none;
}

.header__nav .sub-menu li {
	margin-bottom: 10px;
}

.header__nav .sub-menu a {
	font-size: 16px;
	line-height: 20px;
}

.say__title {
	margin-bottom: 25px;
}

}

@media all and (max-width: 767px) {

.container {
	padding: 0 4vw;
}

.theme-button {
	width: 300px;
	height: 45px;
	font-size: 16px;
}

.main {
	padding: 75px 0;
}

.main-title {
	font-size: 33px;
	line-height: 39px;
}

.header {
	height: 70px;
}

.header__logo img {
	width: 155px;
	display: block;
}

.intro-wrap {
	padding: 100px 0 25px;
}

.intro-wrap__line {
	left: 5px;
}

.intro__title {
	font-size: 42px;
	line-height: 1;
	max-width: 370px;
}

.intro__sub {
	font-size: 16px;
}

.intro__action {
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.helps-wrap {
	margin: 60px 0;
}

.helps-wrap .container {
	padding: 0 4vw;
}

.tabs__links .tabs__item {
	width: 130px !important;
	height: 30px;
	margin: 0 6px;
	font-size: 16px;
	line-height: 20px;
}

.tabs__links {
	justify-content: space-between;
}

.join-wrap {
	margin: 60px 0;
}

.form-theme__border {
	transform: rotate(-8deg);
}

.join__info {
	margin-left: 0;
}

.say-wrap {
	margin: 60px 0;
}

.say-slider__content {
	width: 100%;
	padding: 20px;
	border-radius: 15px;
}

.say-slider__content::after {
	left: 5px;
	bottom: -20px;
	width: 15px;
	height: 15px;
}

.say-slider__post {
	font-size: 14px;
	line-height: 18px;
}

.slick-current .say-slider__content::before {
	left: -23px;
	top: -94px;
	font-size: 150px;
}

.say-slider-previous {
	left: -20px;
}

.say-slider-next {
	right: -20px;
}

.partners-wrap {
	margin: 60px 0;
}

.partners-wrap__line {
	top: 0;
	max-height: 75%;
}

.partners-wrap__border-right {
	top: calc(50% + 15px);
}

.partners-wrap__border-bottom {
	bottom: -15px;
}

.partners-block__item {
	grid-template-columns: 25% 25% 25% 25%;
}

.save-wrap {
	margin: 60px 0;
}

.save-block {
	flex-direction: column;
}

.save-timeline__title {
	font-size: 14px;
	line-height: 18px;
}

.access__item {
	font-size: 16px;
	line-height: 20px;
	margin: 0px 8px;
}

.access__item::before {
	top: 2px;
}

.footer__navigation {
	width: 100%;
}

.footer__socials {
	width: 100%;
	flex-wrap: wrap;
	justify-content: center;
}

.footer__socials a {
	margin: 5px;
}

.footer__subscribes {
	width: 100%;
	flex-direction: column;
	align-items: center;
}

.footer__buttons {
	margin-left: 0;
	margin-bottom: 15px;
}

.footer__label {
	text-align: center;
	margin-left: 0;
}

.footer__links a {
	margin-right: 15px;
}

.page-blog {
	justify-content: center;
	margin: 0;
}

.page-blog__thumbnail {
	height: 190px;
}

.page-blog__item {
	padding: 0;
	width: 100%;
	max-width: 350px;
	margin-bottom: 25px;
}

.page-blog__title {
	font-size: 16px;
}

.page-blog__date {
	font-size: 14px;
}

.about-intro-wrap {
	padding: 75px 0 50px;
}

.about-intro-wrap::before {
	display: none;
}

.about-intro__title {
	font-size: 24px;
	margin-bottom: 25px;
}

.about-intro__content {
	font-size: 14px;
	margin-bottom: 15px;
}

.about-intro__content p {
	margin-bottom: 15px;
}

.about-intro__logos {
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.about-intro__logos img {
	margin: 20px 0 !important;
}

.about-team__caption {
	font-size: 16px;
	max-width: 345px;
}

.about-team__photo {
	width: 100px;
	height: 100px;
}

.about-team__photo img {
	max-width: 100%;
}

.about-team__item {
	margin: 0 20px 30px;
}

.about-team__name {
	font-size: 18px;
	margin: 15px 0 5px;
}

.about-team__text {
	font-size: 14px;
}

.about-numbers-wrap {
	padding: 25px 0;
}

.about-numbers__block {
	flex-direction: column;
}

.about-numbers__item {
	width: 100%;
	margin: 0 0 20px;
}

.about-numbers__count {
	font-size: 34px;
}

.about-numbers__bold {
	font-size: 16px;
}

.about-numbers__text {
	font-size: 14px;
}

.join__form {
	margin-bottom: 50px;
}

.try-instruments__gallery--arrow--prev {
	left: 0;
}

.try-instruments__gallery--arrow--next {
	right: 0;
}

.not-found-wrap {
	padding-top: 100px;
}

.not-found-wrap::before {
	display: none;
}

.not-found {
	flex-wrap: wrap;
}

.not-found__number {
	font-size: 130px;
}

.not-found__links {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.not-found__links a {
	margin: 15px 0;
	padding: 10px 40px;
}

.tariff-intro-wrap {
	padding: 100px 0 25px;
}

.tariff-intro__title {
	font-size: 24px;
	margin-bottom: 25px;
}

.tariff-intro__text {
	max-width: 320px;
	font-size: 14px;
	margin: 0 auto 25px;
}

.tariff__radio {
	margin-bottom: 25px;
}

.tariff__item {
	padding: 20px 0;
	width: 100%;
	max-width: 370px;
}

.tariff__title {
	font-size: 20px;
	margin-bottom: 10px;
}

.tariff__price {
	margin-bottom: 15px;
}

.tariff-radio {
	margin: 0;
	flex-direction: column;
	align-items: center;
}

.tariff-radio__label {
	margin: 0 auto 20px;
}

.compare-wrap {
	padding: 80px 0 25px;
}

.compare-block > div {
	padding: 10px;
}

.compare-block > div.compare-block__head {
	padding: 15px 10px;
}

.faq-wrap {
	padding: 0 0 25px;
}

.faq-list__question {
	padding: 15px 40px 15px 20px;
	font-size: 14px;
}

.faq-list__question::after {
	top: 20px;
}

.faq-list__answer {
	padding: 10px 10px 10px 20px;
	font-size: 14px;
}

.intro-instruments-wrap {
	padding: 100px 0 25px;
}

.intro-instruments-wrap::before {
	left: 5px;
}

.intro-instruments__title {
	font-size: 24px;
	margin-bottom: 25px;
}

.intro-instruments__text {
	font-size: 14px;
	margin: 0 auto 30px;
}

.intro-instruments__links {
	flex-direction: column;
	margin-bottom: 0;
}

.intro-instruments__list ul {
	margin: 0;
	flex-direction: column;
	align-items: center;
}

.intro-instruments__list ul li {
	margin: 0 0 20px;
	font-size: 14px;
	width: 200px;
}

.services-instruments__title,
.advantages-instruments__title,
.how-instruments__title,
.try-instruments__title {
	font-size: 24px;
}

.content-instruments-wrap,
.advantages-instruments-wrap,
.how-instruments-wrap,
.see-instruments-wrap {
	padding: 25px 0;
}

.content-instruments__image {
	margin: 0;
}

.content-instruments__info h3 {
	font-size: 18px;
	margin-bottom: 15px;
}

.content-instruments__info p {
	font-size: 14px;
}

.content-instruments-image img {
	max-width: 100%;
}

.advantages-instruments__caption {
	font-size: 18px;
}

.advantages-instruments__text {
	font-size: 14px;
}

.how-instruments .save-timeline__text {
	font-size: 14px;
	text-align: left;
	padding-right: 20px;
}

.how-instruments .save-timeline__item {
	margin-bottom: 20px;
	min-height: 42px;
}

.how-instruments .save-timeline__item::before {
	top: 45%;
	height: 100%;
	width: 4px;
	right: 12px;
}

.how-instruments .save-timeline__item::after {
	top: 45%;
	height: 100%;
	width: 4px;
	right: 12px;
}

.see-instruments__title {
	font-size: 20px;
	margin-bottom: 30px;
}

.see-instruments__text {
	font-size: 16px;
	margin-bottom: 30px;
}

.see-instruments__images {
	width: 100%;
	height: 50vw;
}

.try-instruments__item {
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.try-instruments__ico {
	margin-right: 0;
}

.try-instruments__caption {
	margin: 15px 0;
}

.try-instruments__link {
	margin-bottom: 20px;
}

.try-instruments__more a {
	font-size: 16px;
}

.try-instruments-bullets__item {
	width: 50%;
}

.access__block {
	align-items: center;
}

.access__item {
	width: 170px;
	box-sizing: border-box;
	margin-bottom: 5px;
}

.cat-inst-content__title {
	font-size: 20px;
	line-height: 1.3;
	max-width: 750px;
	text-align: center;
	margin: 0 auto 20px;
}

.cat-inst-content__text {
	font-size: 14px;
	max-width: 750px;
	margin: 0 auto 20px;
	text-align: center;
}

.cat-inst-content__image {
	position: static;
	margin-bottom: 20px;
	text-align: center;
}

.cat-inst-content__image.center {
	transform: translateY(0);
}

.cat-inst-content__block .try-instruments__item {
	width: 100%;
}

.cat-inst-content__block--special .try-instruments__item:first-child {
	width: 100%;
}

.cat-inst-content-wrap {
	padding: 25px 0;
}

.cat-inst-content-wrap.last {
	padding-bottom: 50px;
}

.navigation__links {
	display: flex;
	margin-top: 10px;
	margin-bottom: -20px;
}

.say-slider .slick-slide > div {
	box-sizing: border-box;
	padding: 0 15px;
}

.access__form .form-theme__border {
	transform: rotate(8deg);
}

.tabs__links .slick-slide {
	padding: 0 5px;
	box-sizing: border-box;
}

}

@media all and (max-width: 600px) {

.instruments__items {
	padding: 12px 0 12px 0px;
}

.instruments__title::before {
	left: -20px;
}

}

@media all and (max-width: 580px) {

.intro__image {
	display: none;
}

.intro__title {
	font-size: 34px;
}

}

@media all and (max-width: 500px) {

.main-title {
	font-size: 28px;
	line-height: 35px;
	margin-bottom: 20px;
}

.helps-wrap {
	margin: 30px 0;
}

.tabs__links .tabs__item {
	height: 40px;
	text-align: center;
	width: 100% !important;
}

.join-wrap {
	margin: 30px 0;
}

.form-theme__title {
	font-size: 22px;
	line-height: 26px;
	margin-bottom: 26px;
}

.form-theme__input input:not([type='checkbox']),
.form-theme__input select {
	height: 44px;
	padding: 0 20px;
	font-size: 16px;
}

.form-theme__button {
	margin-top: 30px;
}

.form-theme__row {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.form-theme__row > * {
	margin: 0 0 15px;
}

.form-theme {
	width: 100%;
	height: auto;
	padding: 30px 10px;
}

.say-wrap {
	margin: 30px 0;
}

.say-slider__item {
	height: auto;
}

.say-slider__content {
	height: auto;
	margin-bottom: 25px;
}

.say-slider__avatar {
	font-size: 18px;
	line-height: 22px;
	margin-right: 10px;
}

.say-slider__name {
	font-size: 18px;
	line-height: 22px;
}

.partners-wrap {
	margin: 30px 0;
}

.partners-wrap__line {
	left: 5vw;
}

.partners-wrap__border-right {
	right: 5vw;
}

.partners-wrap__border-bottom {
	right: 5vw;
}

.partners-block__item {
	grid-template-columns: 50% 50%;
}

.save-wrap {
	margin: 30px 0;
}

.save-timeline {
	flex-direction: column;
	text-align: left;
	padding: 0 4vw;
}

.save-timeline__item {
	width: 100%;
	margin-bottom: 20px;
}

.save-timeline__item::before {
	top: 48%;
	height: 100%;
	width: 4px;
	right: 12px;
}

.save-timeline__item::after {
	top: 48%;
	height: 100%;
	width: 4px;
	right: 12px;
}

.save-timeline__dot {
	position: absolute;
	top: 0;
	right: 0;
}

.access__block {
	margin-bottom: 40px;
	flex-direction: column;
}

.access__form {
	margin: 0 -4vw;
}

.footer__top {
	flex-direction: column;
}

.footer__contacts {
	text-align: center;
	margin-top: 20px;
}

.footer__input input:not([type=submit]) {
	width: 200px;
	padding: 0 15px;
}

.footer__input input[type=submit] {
	width: 90px;
}

.footer__bot {
	flex-direction: column;
	align-items: center;
}

.footer__links {
	display: flex;
	flex-direction: column;
	text-align: center;
}

.footer__links a {
	margin-right: 0;
	margin-bottom: 10px;
}

}

